<template>
  <el-popover
    v-model="popoverVisible"
    title="自定义列"
    placement="bottom"
    width="200"
    :popper-class="'dynamic-header-popover' + flag"
  >
    <div style="height: 300px; overflow-y: auto; margin-bottom: 5px">
      <div
        v-for="header in remoteHeaderWrapper"
        :key="header.label + header.prop + flag"
        style="
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          margin-bottom: 12px;
        "
      >
        <input
          :id="header.label + header.prop + flag"
          :class="'dynamic-header-checkbox' + flag"
          type="checkbox"
          :checked="header.isShow"
          @change="onChangeCheckedStatus(header.label + header.prop, $event)"
        />
        <label
          :for="header.label + header.prop + flag"
          style="font-weight: normal"
        >
          {{ header.label }}
        </label>
      </div>
    </div>

    <div style="display: flex; justify-content: flex-end">
      <el-button size="mini" @click="onReset">恢复默认</el-button>
      <el-button size="mini" type="primary" @click="onConfirm">保存</el-button>
    </div>

    <el-button slot="reference" type="text" :size="buttonSize">
      <svg-icon icon-class="i-controls" />
    </el-button>
  </el-popover>
</template>

<script>
export default {
  name: 'DynamicHeaderControls',

  props: {
    buttonSize: {
      type: String,
      default: 'small'
    },
    remoteHeader: {
      type: Array,
      default: () => []
    },

    // 专用于待拆单、待确认的拆单明细、商品明细
    flag: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      popoverVisible: false,
      currentCheckedStatusList: []
    }
  },

  computed: {
    remoteHeaderWrapper() {
      return this.remoteHeader.filter((item) => !item.isInteractiveButton)
    }
  },

  watch: {
    popoverVisible: function (newV) {
      if (newV) {
        this.resetToInitialState()
      }
    }
  },

  created() {
    this.currentCheckedStatusList = this.remoteHeaderWrapper
  },

  methods: {
    resetToInitialState() {
      const checkboxes = document.querySelectorAll(
        '.dynamic-header-checkbox' + this.flag
      )

      checkboxes.forEach((checkbox) => {
        const matchedItem = this.remoteHeaderWrapper.find(
          (item) => checkbox.id === item.label + item.prop + this.flag
        )

        if (matchedItem) {
          checkbox.checked = matchedItem.isShow
        }
      })

      this.currentCheckedStatusList = this.remoteHeaderWrapper
    },

    onReset() {
      this.$emit('reset')

      this.popoverVisible = false
    },

    onConfirm() {
      const isAllCheckboxUnchecked =
        this.currentCheckedStatusList.filter((item) => item.isShow).length === 0

      if (isAllCheckboxUnchecked) {
        return void this.$message.error('不能为空，必须勾选一个字段，请检查')
      }

      this.popoverVisible = false

      this.$emit('confirm', this.currentCheckedStatusList)
    },

    onChangeCheckedStatus(key, e) {
      const currentCheckedStatusList = JSON.parse(
        JSON.stringify(this.currentCheckedStatusList)
      )

      currentCheckedStatusList.find(
        (item) => item.label + item.prop === key
      ).isShow = e.target.checked

      this.currentCheckedStatusList = currentCheckedStatusList
    }
  }
}
</script>
