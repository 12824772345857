import service from '@/service'
/**
 * 动态表头
 * @param {{pageId:number}} params
 */
export function getListHeader(params) {
  return service['api-web']({
    url: '/public/getHeaderBlue',
    method: 'get',
    params
  })
}
/**
 * 数字图片验证码
 */
export function getNumCaptchaImg() {
  return service['api-web']({
    crypt: { type: 'none' },
    url: '/allPublic/getCode',
    method: 'get',
    /**
     * @deprecated
     * 后端返回文件流
     *  ________ start _______
     */
    // responseType: 'blob',
    // saveAs: false,
    // convert: { to: 'blobUrl' },
    /* _________ end ______  */
    /**
     * 后端返回 base64
     */
    base64: {
      /**
       * base64 string of res will be converted, add media type and charset infos
       * @type {{type:string,charset:(string|undefined)}}
       */
      imgCode: { type: 'jpeg' }
    }
  })
}
// /**
//  * @typedef {Object} SmsCodeParams
//  * @property {string} clientId - 客户端ID（getCode 接口返回，用于图片验证码的存储key）
//  * @property {string} userName - 用户名输入域的值（需要 RSA 加密）
//  * @property {string} graphCode - 数字图片验证码输入域的值（需要 RSA 加密）
//  * @param {SmsCodeParams} params
//  */
// export function getSmsCode(params) {
//   return service['api-web']({
//     crypt: { type: 'rsano', encryption: ['userName', 'graphCode'] },
//     url: '/allPublic/sendSms',
//     method: 'get',
//     params
//   })
// }

/**
 * xx图片验证码(其他验证码：如拼图滑块等)
 */
// export function getXXCaptchaImage() {
//   return service['api-web']({
//     url: '/public/',
//     method: 'get'
//   })
// }

/**
 * @param {file} file
 * @description 合同附件上传
 */
export function uploadContract(file) {
  return service['api-web']({
    url: '/public/uploadContract',
    method: 'get',
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @param {string} address 文件访问地址
 * @description 所有文件，文件流下载
 */
export function getFileStream(address) {
  return service['api-web']({
    url: '/public/downloadFile',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    data: { address },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{deptId:number}} params
 * @description 查询部门所有人
 */
export function getAllUserByDeptId(params) {
  return service['api-web']({
    url: 'public/sys/getAllUserByDeptId',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} supplierCodes
 */
export function getSupplierItemsByCodes(supplierCodes) {
  return service['api-web']({
    schema: false,
    url: '/public/data/getNameByCodes',
    method: 'get',
    params: { supplierCodes }
  })
}
/**
 * @param {string} clientCodes
 */
export function getClientItemsByCodes(clientCodes) {
  return service['api-web']({
    schema: false,
    url: '/public/data/getClientInformationByCodes',
    method: 'get',
    params: { clientCodes }
  })
}

/**
 * @descrtption 采购中,判断含税采购价格是否高于产品维护的参考采购成本(三个参数都是必须的,返回true表示高了,返回false表示低于于或者等于参考采购成本)
 */
export function judgePriceWhetherHeight(params) {
  return service['api-web']({
    url: '/public/product/judgePriceWhetherHeight',
    method: 'get',
    params
  })
}
/**
 * @descrtption 销售中,判断含税单价是否低于产品维护的最低折扣价(三个参数都是必须的,返回true表示低了,返回false表示高于或者等于最低折扣价)
 */
export function judgePriceWhetherLow(params) {
  return service['api-web']({
    url: '/public/product/judgePriceWhetherLow',
    method: 'get',
    params
  })
}
/**
 *
 * @param {*} params
 * @returns
 * @description  商品编码(财务管理=>成本专用，作用于前一、后一)
 */
export function getProductCodes(params) {
  return service['api-web']({
    url: '/public/product/getProductCodes',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{productCode:string}} params
 * @description 根据商品编码查询采购员姓名
 */
export function getBuyerByProductCode(params) {
  return service['api-web']({
    url: '/public/product/queryBuyerByProductCode',
    method: 'get',
    params
  })
}
/**
 * 产品列表-校验当前物料分组代码是否重复
 */
export function verifyDuplicate(params) {
  return service['api-web']({
    url: '/public/product/isProductCategoryCodeExist',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    params
  })
}

/**
 * @param {{fileName:string}} params
 * @description 下载模板通用
 * @deprecated 后续下载模板接口均在对应的模块中，此接口不要再使用
 */
export function downloadTemplateCommon(params) {
  return service['api-web']({
    url: '/public/downloadExcel',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 获取所有云仓仓库
 */
export function getCloudWarehouse() {
  return service['api-web']({
    url: '/public/data/getCloudWarehouse',
    method: 'get'
  })
}

/**
 * @description 查询公用的SKU
 */
export function querySharedSku(params) {
  return service['api-web']({
    url: '/public/product/querySharedSku',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{rejectReqQOList:Array<{id:string,taskId:string,assignee?:string,remarks?:string}>,rejectTypeEnum:string}} data
 * @description 批量驳回
 */
export function activitiReject(data) {
  return service['api-web']({
    url: '/public/activiti/batchReject',
    method: 'post',
    data
  })
}

/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 待拆单-修改订单标记
 */
export function orderSeparateUpdateOrderMark(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/updateOrderMark',
    method: 'post',
    data
  })
}

/**
 * @description 待确认-修改订单标记
 */
export function orderConfirmUpdateOrderMark(data) {
  return service['api-scm']({
    url: '/scm/order/orderConfirm/updateOrderMark',
    method: 'post',
    data
  })
}

/**
 * @description 待发货-修改订单标记
 */
export function orderSeparateDetailUpdateOrderMark(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/updateOrderMark',
    method: 'post',
    data
  })
}

/**
 * @description 订单管理-修改订单标记
 */
export function orderInfoUpdateOrderMark(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/updateOrderMark',
    method: 'post',
    data
  })
}

/**
 * @description 销售订单-修改订单标记
 */
export function orderSaleUpdateOrderMark(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/updateOrderMark',
    method: 'post',
    data
  })
}

/**
 * @description 块粘贴商品信息查询
 */
export function queryProducts(data) {
  return service['api-web']({
    url: '/public/product/blockPasteSearchProductInformation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description 块粘贴仓库信息查询
 */
export function queryWarehouse(data) {
  return service['api-web']({
    url: '/public/warehouse/blockPasteSearchWarehouseInformation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * @description 块粘贴库位代码信息查询
 */
export function queryInvoice(data) {
  return service['api-scm']({
    url: '/public/inventory/blockPasteSearchInventoryInformation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description 块粘贴库位代码信息查询
 */
export function blockPasteSearchInventoryForOther(data) {
  return service['api-scm']({
    url: '/public/inventory/blockPasteSearchInventoryForOther',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description 根据供应商和商品集合获取供货授权
 */
export function getSupplierAuthorBySupplierCodeAndProductCode(data) {
  return service['api-web']({
    url: '/public/product/getSupplierAuthorBySupplierCodeAndProductCode',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export function getAllocateWarehousePullList(data) {
  return service['api-web']({
    url: '/public/warehouse/getAllocateWarehouse',
    method: 'post',
    data
  })
}

/** *
 * @description 获取仓库信息
 */
export function getSelectStatusWarehouseInfo(data) {
  return service['api-web']({
    url: '/public/data/getFilterWarehouseInfo',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据供应商编码查询出 地址电话等信息
 */
export function findListBySupplierCode(params) {
  return service['api-web']({
    url: '/public/findListBySupplierCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取供应商授权列表(采购订单使用)
 */
export function supplyAuthorizationList(data) {
  return service['api-web']({
    url: '/public/supplyAuthorizationList',
    method: 'post',
    data
  })
}

/**
 * 判断用户是否属于仓库部门或其子部门
 */
export function belongToWarehouseDeptOrSubDept(params) {
  return service['api-web']({
    url: '/public/sys/belongToWarehouseDeptOrSubDept',
    method: 'get',
    params
  })
}
