/**
 * @module store/modules/filterOpts
 * @description 下拉选项
 */

import Vue from 'vue'
// import halt from '@/utils/halt'
import poll from '@/utils/poll'
import { NETWORK_TIMEOUT } from '@/constants'
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { uppercaseFirst } from '@/utils/string'
import { isDetailPage } from '@/utils/page-type'
import createDropdownMap from '@/utils/dropdown-map'
import { optionsCommonFilter, optionsCommonDisabler } from '@/utils/options'
import { deweight } from '@/utils/array'

import * as webFilterOpts from '@/service/api-web/filter-opts'
import * as scmFilterOpts from '@/service/api-scm/filter-opts'
import * as activitiFilterOpts from '@/service/api-activiti/filter-opts'
import * as externalFilterOpts from '@/service/api-external/filter-opts'
const filterOptsApis = {
  web: webFilterOpts,
  scm: scmFilterOpts,
  activiti: activitiFilterOpts,
  external: externalFilterOpts
}

/**
 * @typedef {Array<import('element-ui/types/option').ElOption>} SelectOptions
 *
 * @typedef {Record<number,import('element-ui/types/option').ElOption>} IdIndex (key:id,value:el-option item)
 * @typedef {Record<number,SelectOptions>} PidIndex (key:pid,value:children)
 *
 * @typedef {Object} Indexes 下拉索引集
 * @property {IdIndex} idx ID 索引
 * @property {PidIndex} pidx PID 索引
 *
 * @typedef {Object} EleOptions 下拉选项集
 * @property {SelectOptions} all 所有 options
 * @property {SelectOptions} parents 所有父 options
 * @property {SelectOptions} children 所有子 options
 *
 * @typedef {Object} DropdownMap 索引和选项地图
 * @property {Indexes} idxs 索引
 * @property {EleOptions} opts 选项
 */
/**
 * @returns {Record<string,SelectOptions>}
 */
const state = () => ({
  /**
   * @type {SelectOptions}
   * @description  用户下拉 (value key: id)
   */
  user: [],
  /**
   * @type {SelectOptions}
   * @description 用户下拉 ( value key: name)
   */
  userName: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 实收金额
   */
  amountOperator: [
    { label: '等于', value: 1 },
    { label: '不等于', value: 2 },
    { label: '小于', value: 3 },
    { label: '大于', value: 4 }
  ],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 角色
   */
  role: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 职位
   */
  job: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 部门
   */
  department: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 政治面貌
   */
  politics: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 民族
   */
  nation: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 学历
   */
  education: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 采购部门下用户下拉框
   */
  userbydepartment: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 字典
   */
  dictionary: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 使用组织ID
   */
  company: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 使用组织名称
   */
  companyName: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 税率 code
   */
  taxRate: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 税率 id
   */
  taxRateId: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 结算币种
   */
  settlementCurrency: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 销售人员
   */
  sellUser: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 销售人员（上面的暂时废弃）
   */
  salesDeptUser: [],
  salesDept: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 供应商分组
   */
  supplierGroup: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 供应商采购员
   */
  supplierBuyer: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 供应商管理专员
   */
  managementSpecialist: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 获取所有云仓下拉框
   */
  cloudWarehouse: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 地区
   */
  district: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品 产品专员
   */
  productSpecialist: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品 标签
   */
  productLabelManagelist: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品 单位 (全量)
   */
  unitOfMeasurement: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品 单位 (可用)
   */
  unitOfMeasurementAvailable: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品 分组
   */
  productCategory: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 客户 分类
   */
  customerCategory: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品管理=>不可售地域
   */
  sellTerritory: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 往来单位
   */
  dealingClient: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 税收制度
   */
  taxSystemCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 税种
   */
  taxTypeCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 库存状态类型
   */
  warehouseStatusType: [],
  /**
   * @deprecated
   * @type {SelectOptions}
   * @description 下拉选项: 结算方式下拉框
   */
  asyncClearingForm: [],
  /**
   * @deprecated
   * @type {SelectOptions}
   * @description 下拉选项: 多选结算方式下拉框（public/data/getClearingForm不支持多个入参为此后端新增了public/data/getClearingFormByIds）
   */
  asyncMutipileClearingForm: [],
  /**
   * @deprecated
   * @type {SelectOptions}
   * @description 下拉选项: 结算方式 (组织 和 结算组织 同时筛选出的并集)
   */
  clearingFormUnion: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 异步获取对应组织下拉框(货主)
   */
  companyInfo: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项：已启用的销售组织
   */
  companyInfoForEnable: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 库存调拨单->  调入/调出(货主)
   */
  shipper: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 仓库 CODE
   */
  warehouse: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 仓库 ID
   */
  warehouseId: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 仓库 NAME
   */
  warehouseName: [],
  /**
   * @type {SelectOptions}
   * @description  下拉选项: 所有启用的仓库
   */
  warehouseAllOn: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 所有启用已审核的仓库 排除供应商直发仓，供应商库存直发仓
   */
  warehouseExcludeDirect: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 物流商
   */
  logisticsCompany: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 京东快递物流商
   */
  jdlogistics: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 物流公司(能够带出账期基本信息)
   */
  logisticsNoContact: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 物流单号
   */
  logisticsNumber: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 承运商
   */
  carrier: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 物流承运商
   */
  logisticsCarrier: [],

  /**
   * @type {SelectOptions}
   * @description 物流查询
   */
  logisticsOrder: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 库位代码
   */
  inventoryCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 出入库类型
   */
  boundTypeCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 入库类型
   */
  inBoundTypeCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 出库类型
   */
  outBoundTypeCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商品编码
   */
  productAncillaryCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 省市
   */
  province: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 标签管理
   */
  productLabelManage: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 流程定义
   */
  processDefinition: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 根据商品编码和仓库编码获取库位代码
   */
  locationCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 根据库位管理主键id获取所有库存明细信息(含批次号)
   */
  inventoryInfo: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 根据调出仓库、调出货主、商品编码、库位代码获取库存数量、可配数信息
   */
  allInventoryProductInfoByBatchCode: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 商店下拉
   */
  storePull: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 费用项目分组下拉
   */
  constitem: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 采购/销售关联的费用项目下拉
   */
  costItemPS: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 销售关联的费用项目下拉
   */
  costItemSales: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 采购关联的费用项目下拉
   */
  costItemPurchase: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 费用项目下拉
   */
  feeItem: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 账簿下拉
   */
  accountBook: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 科目类别下拉
   */
  subjectsCategory: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 获取所有会计日历期间（服务于财务管理成本模块）
   */
  accountCalendarAll: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 获取组织对应的财务下拉框
   */
  companyInfoFinance: [],

  /**
   * @type {SelectOptions}
   * @description 下拉选项: 发货方式
   */
  modeDespatch: [
    { value: 0, label: '仓库直发' },
    // 请勿删除 因需求2895暂时隐藏，后续恢复
    // { value: 1, label: '采购' },
    { value: 2, label: '调拨' },
    { value: 3, label: '待确认' }
  ],
  /**
   * @type {SelectOptions}
   * @description 下拉选项: 账期备注
   */
  paymentDaysNote: [],
  /**
   * @type {SelectOptions}
   * @description 下拉选项:
   */
  carModel: [
    { value: 1, label: '15米重型集装箱半挂车' },
    { value: '2', label: '16米厢式货车' },
    { value: '3', label: '9.6米厢式货车（60m³固定班次）' },
    { value: '4', label: '9.6米厢式货车（60m³）' },
    { value: '5', label: '13.5米重型集装箱半挂车（85m³）' },
    { value: '6', label: '13.5米重型集装箱半挂车（85m³固定班次）' },
    { value: '7', label: '16.5米重型集装箱半挂车（130m³）' },
    { value: '8', label: '16.5米重型集装箱半挂车（130m³固定班次）' },
    { value: '9', label: '17.5米重型集装箱车(180m³)' },
    { value: '10', label: '底盘车-四桥（6-12]-高速' },
    { value: '11', label: '底盘车-三桥（6-12]-高速' },
    { value: '12', label: '6.8米厢式货车围板车' },
    { value: '13', label: '7.6米厢式货车(共营)' },
    { value: '14', label: '9.6米厢式货车(共营)' },
    { value: '15', label: '17.5米重型集装箱半挂车(共营)' },
    { value: '16', label: '4.2米厢式货车(共营)' },
    { value: '17', label: '小型面包车' },
    { value: '18', label: '6.5米厢式货车尾板车' },
    { value: '19', label: '9.6米厢式货车尾板车' },
    { value: '20', label: '7.6米厢式货车尾板车' },
    { value: '21', label: '4.2米厢式货车尾板车' },
    { value: '22', label: '9.6米飞翼厢式货车' },
    { value: '23', label: '9.6米厢式货车（甩箱）' },
    { value: '24', label: '6.2米高栏车' },
    { value: '25', label: '9.6米厢式货车(共建)' },
    { value: '26', label: '17.5米重型集装箱半挂车(共建)' },
    { value: '27', label: '4.2米厢式货车(共建)' },
    { value: '28', label: '12.5米高栏/板车' },
    { value: '29', label: '13.5米高栏' },
    { value: '30', label: '3.1米厢式货车' },
    { value: '31', label: '大通' },
    { value: '32', label: '(新能源)4.2米厢式货车' },
    { value: '33', label: '(新能源)依维柯' },
    { value: '34', label: '(新能源)金杯车' },
    { value: '35', label: '(新能源)小型面包车' },
    { value: '36', label: '17.5米重型集装箱车(150m³)' },
    { value: '37', label: '17.5米板车' },
    { value: '38', label: '17.5米高栏' },
    { value: '39', label: '14.5米板车' },
    { value: '40', label: '14.5米高栏' },
    { value: '41', label: '13.5米板车' },
    { value: '42', label: '12.5米板车' },
    { value: '43', label: '12.5米高栏' },
    { value: '44', label: '9.6米板车' },
    { value: '45', label: '9.6米高栏' },
    { value: '46', label: '7.6米高栏' },
    { value: '47', label: '7.2米板车' },
    { value: '48', label: '7.2米高栏' },
    { value: '49', label: '6.8米板车' },
    { value: '50', label: '6.8米高栏' },
    { value: '51', label: '6.2米板车' },
    { value: '52', label: '6.2米高栏' },
    { value: '53', label: '4.2米板车' },
    { value: '54', label: '4.2米高栏' },
    { value: '55', label: '13.5米厢式货车' },
    { value: '56', label: '8.6米厢式货车' },
    { value: '57', label: '13米板车' },
    { value: '58', label: '13米高栏' },
    { value: '59', label: '17.5米高低板车' },
    { value: '60', label: '20GP' },
    { value: '61', label: '40GP' },
    { value: '62', label: '13米高栏车' },
    { value: '63', label: '16.5米厢式货车' },
    { value: '64', label: '13米厢式货车' },
    { value: '65', label: '14.5米厢式货车' },
    { value: '66', label: '12.5米重型集装箱半挂车' },
    { value: '67', label: '12.5米厢式货车' },
    { value: '68', label: '7.2米高栏/板车' },
    { value: '69', label: '17.5米重型集装箱半挂车' },
    { value: '70', label: '17.5米厢式货车' },
    { value: '71', label: '4.2米高栏/板车' },
    { value: '72', label: '17.5米高栏/板车' },
    { value: '73', label: '9.6米高栏/板车' },
    { value: '74', label: '7.6米高栏/板车' },
    { value: '75', label: '6.8米高栏/板车' },
    { value: '76', label: '13.5米重型集装箱半挂车' },
    { value: '77', label: '5.8米厢式货车' },
    { value: '78', label: '6.8米厢式货车' },
    { value: '79', label: '16.5米重型集装箱半挂车' },
    { value: '80', label: '14.5米重型集装箱半挂车' },
    { value: '81', label: '9.6米厢式货车' },
    { value: '82', label: '7.6米厢式货车' },
    { value: '83', label: '7.2米厢式货车' },
    { value: '84', label: '6.5米厢式货车' },
    { value: '85', label: '6.2米厢式货车' },
    { value: '86', label: '5.6米厢式货车' },
    { value: '87', label: '5.2米厢式货车' },
    { value: '88', label: '4.2米厢式货车' },
    { value: '89', label: '依维柯' },
    { value: '90', label: '金杯车' }
  ]
})

const getters = {
  /**
   * @returns {DropdownMap} 部门下拉 索引/选项 集合 {idxs:{idx,pidx},opts:{all,parents,children}}
   */
  deptDropdownMap: (state) =>
    createDropdownMap(state.department, {
      key: null,
      select: { label: 'deptName' }
    }),
  /**
   * @returns {DropdownMap}  字典下拉 索引/选项 集合 {idxs:{idx,pidx},opts:{all,parents,children}}
   */
  dictDropdownMap: (state) =>
    createDropdownMap(state.dictionary, {
      key: null,
      select: { label: 'msgName' }
    }),
  /**
   * @returns {DropdownMap}  职务下拉 索引/选项 集合 {idxs:{idx,pidx},opts:{all,parents,children}}
   */
  jobDropdownMap: (state) =>
    createDropdownMap(state.job, {
      key: null,
      select: { label: 'jobName' }
    })
}

const mutations = {
  SET_OPTIONS(state, { key, value }) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    } else {
      console.error(
        '【getOpts】losed key:' + key + ' for "SET_OPTIONS" that on state'
      )
    }
  },
  SET_FETCH_COUNT_INCREASE(state, { key, fetchedFlagKey }) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      if (Object.prototype.hasOwnProperty.call(state, fetchedFlagKey)) {
        state[fetchedFlagKey] = state[fetchedFlagKey] + 1
      } else Vue.set(state, fetchedFlagKey, 1)
    } else {
      console.error(
        '【getOpts】losed key:' +
          key +
          ' for "SET_FETCH_COUNT_INCREASE" that on state'
      )
    }
  },
  SET_FETCH_COUNT_DECREASE(state, { fetchedFlagKey }) {
    if (Object.prototype.hasOwnProperty.call(state, fetchedFlagKey)) {
      state[fetchedFlagKey] = state[fetchedFlagKey] - 1
    } else {
      console.error(
        '【getOpts】losed fetchedFlagKey:' +
          fetchedFlagKey +
          ' for "SET_FETCH_COUNT_DECREASE" that on state'
      )
    }
  }
}

/**

 */
const actions = {
  /**
   * @typedef {string} Key prop of state
   * - 'role'
   * - 'job'
   * - 'department'
   * - 'dictionary'
   * - 'politics'
   * - 'nation'
   * - 'education'
   * - 'company'
   * - 'warehouse'
   * - 'logisticsCompany'
   * - ’logisticsNoContact‘
   * - 'companyInfo'
   * - 'settlementCurrency'
   * - 'managementSpecialist'
   * - 'boundTypeCode'
   * - 'inBoundTypeCode'
   * - 'outBoundTypeCode'
   * - 'province'
   * - 'processDefinition'
   * - 'productLabelManage'
   * - 'locationCode'
   * - 'inventoryInfo'
   * - 'orderType'
   * - 'storePull'
   * - 'workOrderStatus'
   * - 'customerCategory'
   * - 'orderSource'
   * - 'allInventoryProductInfoByBatchCode'
   * - 'modeDespatch'
   * - 'cloudWarehouse'
   * ...
   *
   * @typedef {{value:string,label:string}} Match
   * @typedef {Array<(SelectOptions|undefined)>} Options
   *
   * @typedef {Object} GetOptionsActionPayload payload for "getOpts"
   * @property {'filter'|'full'|'entire'|'raw'} [mode=filter] - 决定返回结果不同：filter:数组，过滤出启用和已审核的数据 | full:数组，全量数据 | entite:对象，both filer and full | raw:数组，原始数据
   * @property {'web'|'activiti'|'scm'} [service=web] - service 服务名
   * @property {Key} key 请求下拉的 key, 用于拼接请求下拉的 request function name `get${key}Opts`
   * @property {Match=} match label 和 value 字段映射匹配
   * @property {Record<string,never>=} params 请求下拉的网络请求 payload
   * @property {boolean=} force 是否绕开状态库强制远程获取, 默认传了 params 过滤的为 true，没有 params 过滤的 false
   *
   * @param {import('vuex').ActionContext} actionContext
   * @param {(GetOptionsActionPayload|Array<GetOptionsActionPayload>)} payload
   * @returns {Promise<(Options|{filter:Options,full:Options}|Array<Options|{filter:Options,full:Options}>|undefined)>}
   *
   * @description 获取的下拉选项， 支持批量获取
   *
   * - 晦涩的逻辑设计说明：
   *    1. 未传递 `params` 请求参数的，全量的返回结果将自动提升到 `state` 中，不用每次发请求更新，可以按需更新
   *    2. 传递了 `params` 请求参数的，非全量的返回结果将**不存储**到 `state` 中，适用于实时请求获取的场景(比如模糊查询等)
   *    3. 在映射 el-select options 的字段名 value,label 同时，将保留原始的所有字段
   *    4. 之所以前端存在过滤 已审核/已启用等 的相关逻辑，因为设计的时候，大部分接口未开发相关过滤功能
   */
  async getOpts(ctx, payload) {
    const _payloads = isArray(payload) ? payload : [payload]

    const results = await Promise.all(
      _payloads.map((p) => getOptsPromise(ctx, p))
    )

    return _payloads.length === 1 ? results[0] : results
  },
  /**
   * @typedef {Object} GetLablePayload payload for "getLabelByValue"
   * @property {string} value
   * @property {GetOptionsActionPayload} getOptsPaylod
   *
   * @param {import('vuex').ActionContext} actionContext
   * @param {GetLablePayload} payload
   * @returns {(string|undefined)}
   * @description 传入 value 获取下拉选项中对应的 label
   */
  async getLabelByValue({ dispatch }, { value, getOptsPaylod }) {
    try {
      const opts = await dispatch('getOpts', { ...getOptsPaylod, force: false })
      return isArray(opts) && opts.length
        ? opts.find((v) => v.value === value)?.label
        : undefined
    } catch (e) {
      console.error(e)
      return undefined
    }
  },
  /**
   * @typedef {Object} GetValuePayload payload for "getValueByLabel"
   * @property {string} label
   * @property {GetOptionsActionPayload} getOptsPaylod
   *
   * @param {import('vuex').ActionContext} actionContext
   * @param {GetValuePayload} payload
   * @returns {(string|number|undefined)}
   * @description 传入 label 获取下拉选项中对应的 value
   */
  async getValueByLabel({ dispatch }, { label, getOptsPaylod }) {
    try {
      const opts = await dispatch('getOpts', { ...getOptsPaylod, force: false })
      return isArray(opts) && opts.length
        ? opts.find((v) => v.label === label)?.value
        : undefined
    } catch (e) {
      console.error(e)
      return undefined
    }
  },
  emptyOpts({ commit }, payload) {
    const { key = '' } = payload

    if (isString(key) && key.length) {
      commit('SET_OPTIONS', { key, value: [] })
    } else console.error('【emptyOpts】losed key:', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

async function getOptsPromise(
  { state, commit, dispatch, rootGetters },
  payload
) {
  try {
    const {
      mode = 'filter',
      service = 'web',
      key,
      match = { value: '', label: '' },
      params = {}
    } = payload

    if (!key || !isString(key)) {
      console.error('【getOpts】illegal "key" for "getOpts"')
      return []
    }

    if (!isPlainObject(params)) {
      console.error('【getOpts】illegal "params" for "getOpts"')
      return []
    }

    const hasParams = Object.keys(params).length > 0
    /**
     * 请求拦截1，如果没有传递了 params, 视为没有参数过滤的下拉，则会存储到 state 中，所以可以优先使用 state 数据
     */
    const force =
      typeof payload?.force === 'boolean' ? payload.force : hasParams
    if (force !== true) {
      const cached = getCached(rootGetters, state, key)
      if (cached === false) return []
      if (isArray(cached)) return cached
    }

    const fetchedFlagKey = getFetchedFlagKey({
      service,
      key,
      match,
      params
      // params: _params
    })

    /**
     * 请求拦截2，没有参数的下拉，可以在此处释放请求池处理相同地址相同参数的并行压力，via poll 轮询共用第一次请求结果
     */
    if (!hasParams) {
      if (state[fetchedFlagKey] > 0) {
        const cached = getCached(rootGetters, state, key)
        if (cached === false) return []
        if (isArray(cached)) return cached

        const polled = await poll({
          target: () => state,
          nested: [key, length],
          timeout: NETWORK_TIMEOUT.request
        })
        if (polled > 0) {
          const cached = getCached(rootGetters, state, key)
          if (cached === false) return []
          if (isArray(cached)) return cached
        }
      }
    }

    /**
     * apis
     * @type {Record<string,Promise<import('axios').AxiosResponse>>}
     * @description 服务划分下的 service/xxx/sys-tree.js
     */
    const apis = filterOptsApis[service]
    if (typeof apis !== 'object') {
      console.error(
        '【getOpts】losed "apis" for "getOpts":',
        filterOptsApis,
        service,
        apis
      )
      return []
    }
    /**
     * apiName
     * @type {string}
     * @description 在 api 中定义的请求下拉选项列表的 request name
     */
    const apiName = `get${uppercaseFirst(key)}Opts`
    if (typeof apis[apiName] !== 'function') {
      console.error(
        '【getOpts】losed api for "getOpts":',
        filterOptsApis,
        service,
        apis,
        apiName
      )
      return []
    }

    commit('SET_FETCH_COUNT_INCREASE', { key, fetchedFlagKey })
    const { data = [] } = await apis[apiName](/* _params */ params).finally(
      () => commit('SET_FETCH_COUNT_DECREASE', { fetchedFlagKey })
    )

    let opts = transformOptions(mode, data, match)

    console.groupCollapsed(`【getOpts】${key} (remote)`)
    console.log(`${key} match:`, match)
    console.log(`${key} options:`, JSON.parse(JSON.stringify(opts)))
    if (opts.length > 1000) {
      /**
       * TODO: ⚠️ 意外边界情况：opts 数据过大，agel-form 的 select 会阻塞页面渲染
       * 这里只截取 1000 条数据，临时规避隐患
       * 后续如果增加分页，可以删除词截取的逻辑
       */
      opts = opts.slice(0, 1000)
      console.warn(
        `"${key}":  options size is too large, which may cause page rendering blocking`
      )
      console.warn(` 1. Only intercept 1000 length of opts`)
      console.warn(` 2. Not store into the state`)
    }
    console.groupEnd()
    /**
     *liuchao——特殊需求： 资料所有【使用组织】>>选项【本六贸易中心】放在最后一项
     *补充：考虑禁用数据的情况，放在禁用数据的上面一个
     */
    if (key === 'company') {
      const item = opts.find((item) => {
        return item.label === '上海本六贸易中心(普通合伙)'
      })
      if (item) {
        const newOpts = opts.filter((item) => {
          return item.label !== '上海本六贸易中心(普通合伙)'
        })
        // 找到有禁用选项的下标 没有找到返回-1
        const disableIndex = newOpts.findIndex((item) => {
          return item.disabled === true
        })
        if (disableIndex === -1) {
          newOpts.push(item)
        } else {
          // 插入到禁用的那一项的上面
          newOpts.splice(disableIndex, 0, item)
        }
        opts = newOpts
      }
    }
    !hasParams && commit('SET_OPTIONS', { key, value: opts })

    return opts
    // } else return opts
  } catch (error) {
    console.error('【getOpts】catched error', error)
    return []
  }
}

function getFetchedFlagKey({ service, key, match, params }) {
  return JSON.stringify({ service, key, match, params })
}
/**
 * @param {*} rootGetters
 * @param {*} state
 * @param {*} key
 * @returns {(Boolean|Array)}
 */
function getCached(rootGetters, state, key) {
  const gettersKey = key === 'department' ? 'dept' : key
  const gettersOpts = rootGetters[`${gettersKey}Opts`]
  const opts = isArray(gettersOpts)
    ? gettersOpts
    : isArray(gettersOpts?.all)
    ? gettersOpts.all
    : state[key]

  if (isArray(opts)) {
    if (opts.length > 0) {
      // console.groupCollapsed(`【getOpts】${key} (local)`)
      // console.log(`${key} match:`, match)
      // console.log(`${key} options:`, JSON.parse(JSON.stringify(opts)))
      // console.groupEnd()
      return opts
    } else return true
  } else {
    console.error('【getOpts】not defined "key" for "getOpts"', key)
    return false
  }
}
/**
 * @param {('filter'|'full'|'entire'｜'raw')} [mode=filter] - 决定返回结果不同：filter:数组，过滤出启用和已审核的数据 | full:数组，全量数据 | entite:对象，both filer and full | raw:数组，原始数据
 * @param {Array} data list from service
 * @param {Match} match 字段匹配
 * @returns {SelectOptions} - el select options array
 */
function transformOptions(mode = 'filter', data, match) {
  let result = []

  try {
    if (!isArray(data)) {
      console.error(`【getOpts】"data" not array, can't transform`, data)
      return result
    }

    if (data.length === 0) {
      console.warn('【getOpts】"data" empty, non-transform')
      return result
    }

    // 去除异常项
    const _data = data.filter((o) => isPlainObject(o))
    if (_data.length !== data.length) {
      console.warn(`【getOpts】drop some illegal option item of "data"`, data)
    }
    // label/value 映射
    const { value: valKey = '', label: labKey = '' } = isPlainObject(match)
      ? match
      : {}
    const _valKey = isString(valKey) ? valKey.trim() : ''
    const _labKey = isString(labKey) ? labKey.trim() : ''
    /**
     * @type {boolean}
     * @description
     */
    const needMatch = !!(_valKey && _labKey)
    if (!needMatch) console.warn(`【getOpts】non-match`, match)
    const __data = _data.map((v) => ({
      label: v[_labKey],
      value: v[_valKey],
      ...v
    }))
    if (
      __data.some(
        (v) =>
          typeof v?.value === 'undefined' || typeof v?.label === 'undefined'
      )
    ) {
      console.error(`【filterOptions】some item's value/label is undefined`, [
        ...__data
      ])
    }

    // 去重
    const deweighted = deweight(__data, valKey || 'value', { quiet: false })
    if (deweighted.length !== __data.length) {
      console.warn(`【getOpts】drop duplidation option item of "data"`)
    }
    switch (mode) {
      case 'filter':
        result = filterOptions.call(this, deweighted)
        break
      case 'full':
        result = deweighted
        break
      case 'entire':
        result = {
          filtered: filterOptions.call(this, deweighted),
          full: deweighted
        }
        break
      case 'raw':
        result = data
        break
      default:
        console.error('【getOpts】illegal "mode"', mode)
        break
    }
  } catch (error) {
    console.error(`【getOpts】transformOptions catched error`, error)
  }
  return result
}

/**
 * @param {Array} data data array from api's response
 * @returns {SelectOptions} - el select options array
 * @description 下拉选项过滤器
 * 1. 列表页（筛选查询表单），直接删除
 * 2. 详情页（详情表单），不删除，只做禁用，
 * 3. 其他：例如表格的自定义渲染器 ElPicker 的 options 也会直接删除
 */
function filterOptions(data) {
  let currentIsDetailPage = false

  if (this?._vm?.$route?.name) {
    currentIsDetailPage = isDetailPage(this._vm.$route.name)
  } else {
    const pathArr = window?.location?.pathname?.split('/') || []
    currentIsDetailPage = pathArr[pathArr.length - 2] === 'detail'
  }

  const result = currentIsDetailPage
    ? optionsCommonDisabler(data)
    : optionsCommonFilter(data)

  return result
}
