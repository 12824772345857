/**
 * @module utils/files
 */

import { Message } from 'element-ui'
import { isString } from '@/utils/validate'
/**
 * @typedef {
 * import('axios').AxiosResponse} Response
 */

/**
 * @param {Response} response
 * @param {string} title 自定义文件名
 * @returns {Promise<boolean>}
 * @description 通过文件流下载
 */
export function downloadViaFileStream(response, title = '') {
  return new Promise((resolve) => {
    console.groupCollapsed('download file stream')
    console.log(title)
    console.log(response)
    try {
      const filename =
        isString(title) && title.trim()
          ? title.trim() // 自定义文件名
          : getFilenameFromHeaders(response?.headers) // 从后端设置的响应头中获取文件名

      if (typeof window.navigator.msSaveBlob === 'function') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(
          createBlob(response.data),
          decodeURI(filename)
        )
      } else {
        const blobURL = createBlobURL(response.data)

        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', decodeURI(filename))

        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
      }
      console.groupEnd()
      resolve(true)
    } catch (error) {
      console.error(error)
      resolve(false)
    }
  })
}

/**
 * 通过文件远程地址直接下载
 * @param {string} url 文件远程地址
 */
export const downloadViaFileUrl = (url) => {
  try {
    var link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error) {
    window.location.href = url
    console.error('download error: ' + url + ' ' + error)
  }
}

/**
 * @typedef Options 上传限制具体信息
 * @property {number} size 文件大小，单位只有 kb
 * @property {string[]} type 文件类型
 * @property {number=} width 图片宽度
 * @property {number=} height 图片高度
 * @property {number=} offset 误差值(%）
 */

/**
 * 文件上传限制
 * @param {File} file file 对象
 * @param {Options} opts 配置
 * @returns {Promise<boolean>}
 */
export function uploadLimit(
  file,
  { size = 0, type = [''], width = 0, height = 0, offset = 3 }
) {
  return new Promise((resolve, reject) => {
    const { size: _size, raw } = file

    if (Array.isArray(type)) {
      if (type.indexOf(raw.type.split('/')[1]) === -1) {
        Message({
          type: 'warning',
          message: `目前仅支持 ${type.join('、')} 格式的文件`,
          showClose: true
        })
        reject(false)
      } else {
        resolve(true)
      }
    }

    if (size) {
      const isLt = _size && _size / 1024 > size
      if (isLt) {
        Message({
          type: 'warning',
          message: `文件大小上限 ${size} kb`,
          showClose: true
        })
        reject(false)
      }
    }

    // 图片
    if (width && height) {
      const URL = window.URL.createObjectURL(raw)
      const image = new Image()
      image.src = URL
      image.onload = function () {
        const { width: _width, height: _height } = image
        const _aspectRatio = (_width / _height) * 100
        const aspectRatio = (width / height) * 100

        if (Math.abs(_aspectRatio - aspectRatio) > offset) {
          Message({
            type: 'warning',
            message: `图片需要符合宽高比：${width}*${height}`,
            showClose: true
          })
          reject(false)
        } else {
          resolve(true)
        }
      }
      image.onerror = function (err) {
        reject(false)
        console.error(err || '图片加载失败')
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * @description 导出文件标题校验
 * @param {string} disposition
 * @returns {string}
 */
export function getFilenameFromHeaders(headers = {}) {
  /**
   * @type {string}
   * @description e.g: "attachment;filename*=utf-8''%E5%AE%A2%E6%88%B7%E8%B5%84%E6%96%996-14.xlsx"
   */
  const disposition =
    headers['content-disposition'] || headers['Content-Disposition'] || ''

  let filename = ''

  if (disposition) {
    try {
      const res = disposition.match(/filename=(.*)/)?.[1]
      filename = res ? decodeURI(res) : ''
    } catch (e) {
      console.warn('get download file name:', e)
    }

    if (!filename) {
      try {
        filename = decodeURI(disposition.match(/title(\S*)(.*)/)[1])
      } catch (e) {
        console.warn('get download file name:', e)
      }
    }
  }
  console.log('content-disposition:', filename)

  if (!filename) filename = 'unknown-file'

  return filename
}

/**
 * @param {*} data - 文件流
 * @returns {Blob}
 */
export function createBlob(data) {
  return new Blob([data], {
    type: 'application/octet-stream;charset=UTF-8'
  })
}

/**
 * @param {*} data - 文件流
 * @returns {string} - 指向File对象或者Blob对象的 url地址, 例：blob:http://...
 */
export function createBlobURL(data) {
  const blob = createBlob(data)
  const blobURL = window.URL.createObjectURL(blob)
  return blobURL
}
