/**
 * @module api-web:xfs
 */
import service from '@/service'

/**
 * 列表查询
 *
 */
export function getList(data) {
  return service['api-web']({
    url: '/data/xfsProduct/Information/getList',
    method: 'post',
    data
  })
}

/**
 *
 * 对接商品信息
 */

export function dockProduct(data) {
  return service['api-web']({
    url: '/data/xfsProduct/Information/dockProduct',
    method: 'post',
    data
  })
}

/**
 *
 * 对接价格
 */
export function modifyPrice(data) {
  return service['api-web']({
    url: '/data/xfsProduct/Information/modifyPrice',
    method: 'post',
    data
  })
}
