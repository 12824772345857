/**
 * @module api-web:goods/index
 * @description api for data/goods
 */
import service from '@/service'

/**
 * 查询商品列表
 * @param {{companyId:number,thirdPartyCompanyId:number}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/product/Information/getList',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
/**
 * 保存商品
 */
export function create(data) {
  return service['api-web']({
    url: '/data/product/Information/save/saveAll',
    method: 'post',
    data
  })
}

/**
 * 商品列表-删除
 * @param {{ids:Array}} ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/product/Information/deleteCreateStatusProduct',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 编辑商品
 */
export function update(data) {
  return service['api-web']({
    url: '/data/product/Information/updateAll',
    method: 'post',
    data
  })
}

/**
 * @param {string} id
 */
export function info(id) {
  return service['api-web']({
    url: '/data/product/Information/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} id
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/Information/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{id:string}} - data
 * @returns {string} 商品待办提交审核
 */
export function audit(data) {
  return service['api-web']({
    url: '/data/product/Information/completedAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @returns {string} 商品管理提交下拉(结束流程)
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/data/product/Information/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @returns {string} 商品管理提交下拉(提交)
 */
export function submit(ids) {
  return service['api-web']({
    url: '/data/product/Information/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/data/product/Information/reject',
    url: '/public/activiti/Information/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 启用禁用
 * @param {(string|number)} id - id
 * @param {(0|1)} status - 0:禁用 1:启用
 */
export function enableDisable(data) {
  return service['api-web']({
    url: '/data/product/Information/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export function getroductLabelManage(params) {
  return service['api-web']({
    url: '/public/product/getProductLabelManage',
    method: 'get',
    params
  })
}

/**
 * @description 渠道价格 导出数据
 */
export function exportProductChannelData(data) {
  return service['api-web']({
    url: '/data/product/Channel/exportProductChannelData',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * @description 采购价格 导出数据
 */
export function exportProductProcurementData(data) {
  return service['api-web']({
    url: '/data/product/Procurement/exportProductProcurementData',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * @description 商品资料 导出数据
 */
export function exportData(data) {
  return service['api-web']({
    url: '/data/product/Information/productInfoData/export',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 商品资料Excel导入
 */
export function importProductInfoData(data) {
  return service['api-web']({
    url: '/data/product/Information/importProductInfoData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 * @description 渠道价格信息Excel导入
 */
export function importProductChannelData(data) {
  return service['api-web']({
    url: '/data/product/Channel/importProductChannelData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 * @description 采购价格Excel导入
 */
export function importPurchasePriceToSupply(data) {
  return service['api-web']({
    url: '/data/product/Procurement/importPurchasePriceToSupply',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 *
 * @param {object} params
 * @returns {Boolean}
 * @description 判断重复的商品编码
 */
export function queryRepeatGoodsCode(params) {
  return service['api-web']({
    url: '/public/product/isProductCodeExist',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {object} params
 * @returns {Array}
 * @description 不同步官网
 */
export function noSyncWebsite(data) {
  return service['api-web']({
    url: '/data/product/Information/noSyncWebsite',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {object} params
 * @returns {Array}
 * @description 批量同步官网
 */
export function batchSyncWebsite(data) {
  return service['api-web']({
    url: '/data/product/Information/batchSyncWebsite',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 物料一级分类(商品目录一级分类)
 */
export function fetchGoodsDirFirstClassify(params) {
  return service['api-web']({
    schema: false,
    url: '/data/product/Category/getPList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 产品列表-批量上传图片
 */
export function batchUpload(data) {
  return service['api-web']({
    url: '/data/product/Information/save/multipleProductFileUpload',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * 批量文件上传File
 */
export function batchUploadFile(data) {
  return service['api-web']({
    url: '/public/batchUploadFile',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * 批量移动商品
 */
export function batchMoveProductCategory(data) {
  return service['api-web']({
    url: '/data/product/Information/batchMoveProductCategory',
    method: 'post',
    data
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-web']({
    url: '/data/product/Information/manualDockingOfKingdee',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 公用SKU
 * @param {string} ids
 */
export function sharedSku(ids) {
  return service['api-web']({
    url: '/data/product/Information/sharedSku',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 商品信息反审核
 * @param {string} skuId
 */
export function rollback(skuId) {
  return service['api-web']({
    url: '/data/product/Information/reverseAudit',
    method: 'post',
    data: { skuId },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 商品信息反审核(批量)
 * @param {{ids:Array<string|number>}} data
 */
export function batchReverseAudit(data) {
  return service['api-web']({
    url: '/data/product/Information/batchReverseAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批改字段
 * @param {data} data
 * @returns
 */
export function markingParams(data) {
  return service['api-web']({
    url: '/data/product/Information/markingParams',
    method: 'post',
    data
  })
}

/**
 * 批量启用
 * @param {data} data
 */
export function batchUpdateEnableStatus(data) {
  return service['api-web']({
    url: '/data/product/Information/batchEnableSku',
    method: 'post',
    data
  })
}

/**
 * 批量禁用
 * @param {data} data
 */
export function batchUpdateDisableStatus(data) {
  return service['api-web']({
    url: '/data/product/Information/batchForbidSku',
    method: 'post',
    data
  })
}

/**
 * @description 查看采购价格信息
 */
export function queryPurchasePrice(params) {
  return service['api-web']({
    url: '/data/product/Information/queryPurchasePrice',
    method: 'get',
    params
  })
}

/**
 * @description 渠道价格信息判断权限
 */
export function getListByCodes(params) {
  return service['api-web']({
    url: '/data/product/Channel/getListByCodes',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    params
  })
}

/**
 * @description 新品日期下拉框（已审核已启用）
 */
export function getProductNewDates(params) {
  return service['api-web']({
    url: '/public/data/getProductNewDates',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    params
  })
}

/**
 * @description 根据编码和类型获取操作日志
 */
export function getLog(params) {
  return service['api-web']({
    url: '/data/product/Information/getLogsByCodeAndType',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 移动物料大类
 */
export function batchUpdateProductBigType(data) {
  return service['api-web']({
    url: '/data/product/Information/batchUpdateProductBigType',
    method: 'post',
    data
  })
}

/**
 * @description 标签打印
 */
export function printLabel(data) {
  return service['api-web']({
    url: '/data/product/Information/printLabel',
    method: 'post',
    data
  })
}
