/**
 * @module api-scm:logisticsBasicInformation
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsBasicInformation -- 物流基础信息列表
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} companyName - 物流公司
 * @property {number} isMonthly - 预付
 * @property {number} businessState - 业务状态
 * @property {string} purchaseCode - 单据编号
 * @property {string} logisticsOrder - 物流单号
 * @property {string} paymentTimeStart - 下单时间起始
 * @property {string} paymentTimeEnd - 下单时间结束
 *
 * @param {logisticsBasicInformation}
 */

export function getList(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/getList',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/deleteByIds',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 导出
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/export',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 提交
 */
export function submitAudit(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/submitAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 物流下单
 *
 */
export function batchLogisticsOrder(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/batchLogisticsOrder',
    method: 'post',
    data
  })
}
/**
 * 作废
 *  @param {{ids:Array<number>}} params
 */
export function invalid(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsOrder/cancellationByIds',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *根据物流单号与关联单号查询是否已存在关联(true-存在，false-不存在)
 * @param {Obejct} params
 * @returns {Boolean}
 */
export function compareByLogisticAndAssociateCode(params) {
  return service['api-scm']({
    url: '/public/logistics/compareByLogisticAndAssociateCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *根据物流公司编码以及结算组织id获取物流商关联使用组织的结算方式
 * @param {Obejct} params
 * @returns {Obejct}
 */
export function getSettlementMethod(params) {
  return service['api-scm']({
    url: '/public/logistics/getSettlementMethod',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 物流单打印 => 物流公司下拉
 */
export function getLogisticsCompany(params) {
  return service['api-scm']({
    url: '/public/logistics/findCarrierList',
    method: 'get',
    params: {
      ...params,
      flag: 0
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
