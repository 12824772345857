/**
 * @module api-web:goods/index
 * @description api for data/goods
 */
import service from '@/service'

/**
 * 查询商品列表
 * @param {{companyId:number,thirdPartyCompanyId:number}} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/catalog/query/card/pagelist',
    method: 'post',
    data
  })
}
// 获取分组tree 列表
export function getTreeList(params) {
  return service['api-web']({
    url: '/catalog/category/getTreeList',
    method: 'get',
    params
  })
}

// 新增 分组tree
export function addTreeNode(data) {
  return service['api-web']({
    url: '/catalog/category/save',
    method: 'post',
    data
  })
}

// 编辑 分组tree
export function editTreeNode(data) {
  return service['api-web']({
    url: '/catalog/category/edit',
    method: 'post',
    data
  })
}

// 删除 分组tree
export function deleteTreeNode(id) {
  return service['api-web']({
    url: `/catalog/category/delete/${id}`,
    method: 'get'
  })
}
// 创建 目录卡片
export function addCard(data) {
  return service['api-web']({
    url: `/catalog/create/card`,
    method: 'post',
    data
  })
}

// 删除 目录卡片
export function deleteCard(data) {
  return service['api-web']({
    url: `/catalog/delete/card`,
    method: 'post',
    data
  })
}

// 查询生成 目录卡片
export function generateCard(data) {
  return service['api-web']({
    url: `/catalog/query/card/generate`,
    method: 'post',
    data
  })
}

// 查询商品信息
export function queryProductBySpuPage(data) {
  return service['api-web']({
    url: `/data/product/Information/queryProductBySpuPage`,
    method: 'post',
    data
  })
}
