import { getUserTableSort, setUserTableSort } from '@/service/tableDrag'
import store from '@/store'
import { JSONStringify, JSONParse } from '@/utils/jsonModification'
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'

export default {
  name: 'DragColumnMixin',

  data() {
    return {
      mixinColumns: [],
      sortable: null
    }
  },
  mounted() {
    this.$_columnDrop()

    this.$_setSortable()
  },

  beforeDestory() {
    this.$_dropSortable()
  },

  methods: {
    $_columnDrop() {
      const wrapperTr = document.querySelector(
        `.vtable.${this.tableKey} .el-table__header-wrapper tr`
      )

      this.sortable = Sortable.create(wrapperTr, {
        animation: 500,
        handle: '.table-head',
        filter: '.ignore-elements',
        draggable: '.able-elements',
        direction: 'vertical',
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag',
        forceFallback: true,
        onMove(e) {
          return e.related.className.indexOf('ignore-elements') === -1
        },
        onEnd: (evt) => {
          // 拖拽前将所有列的过滤值保存下来
          const column = this.$refs['table'].getTableColumn()
          this.filterColumnIndexList.forEach((item) => {
            item.filteredValue = column.find(
              (i) => i.property === item.prop
            ).filteredValue
          })
          let index = 0
          if (this.showIndex) index++

          if (this.$scopedSlots.selection) index++
          const selectionItem = this.mixinColumns.filter(
            (item) => item?.prop === 'index'
          )

          if (selectionItem.length > 0 && this.showIndex) {
            index--
          }

          const newItem = this.mixinColumns[evt.newIndex - index]
          const oldItem = this.mixinColumns[evt.oldIndex - index]

          if (!newItem || !oldItem) {
            return
          }

          if (
            newItem?.['prop'] === 'index' ||
            newItem?.['type'] === 'selection' ||
            oldItem?.['prop'] === 'index' ||
            oldItem?.['type'] === 'selection'
          ) {
            return
          }

          let columnCopy = cloneDeep(this.mixinColumns)

          columnCopy.splice(
            evt.newIndex - index,
            0,
            columnCopy.splice(evt.oldIndex - index, 1)[0]
          )

          this.mixinColumns = []

          this.$nextTick(() => {
            this.mixinColumns = columnCopy
            setUserTableSort({
              userId: store.state.user.id,
              projectName: 'erp',
              systemType: store.state.user.jobId,
              tableId: this.tableKey,
              columns: JSONStringify(this.mixinColumns)
            })

            // 清除副本
            columnCopy = null

            if (typeof this.handleDragEnd === 'function') {
              this.handleDragEnd(evt)
            }
          })
        }
      })
    },

    $_setSortable() {
      return getUserTableSort(
        store.state.user.id,
        'erp',
        store.state.user.jobId,
        this.tableKey
      ).then(
        (res) => {
          if (res.code === 200 && res.info && res.info.columns) {
            var serverCol = JSONParse(res.info.columns)
            var reorderedArray = serverCol.map((item2) => {
              const matchedItem = this.header.find((item1) => {
                const label1 = item1.label || item1.type
                const prop1 = item1.prop || item1.props

                const label2 = item2.label || item2.type
                const prop2 = item2.prop || item2.props

                return label1 === label2 && prop1 === prop2
              })

              return matchedItem ?? {}
            })
            if (reorderedArray.length > 0) {
              this.mixinColumns = reorderedArray
            } else {
              this.mixinColumns = this.header
            }
          }
        },
        () => {
          this.mixinColumns = this.header
        }
      )
    },

    $_dropSortable() {
      this.sortable = null
    }
  }
}
