import service from '@/service'

/**
 * @description 物流对接列表查询
 */
export function getList(data) {
  return service['api-web']({
    url: '/dc/logisticsDockList/jd/get/getList',
    method: 'post',
    data
  })
}

/**
 * @description 再次对接
 */
export function manualDock(data) {
  return service['api-web']({
    url: '/dc/logisticsDockList/jd/manualDock',
    method: 'post',
    data
  })
}

/**
 * @description 标记完成
 */
export function markDone(data) {
  return service['api-web']({
    url: '/dc/logisticsDockList/jd/markDone',
    method: 'post',
    data
  })
}

export { getOrderLog } from '@/service/api-scm/orderManage/orderInfo'

export {
  getList as getOrderOutCodeId,
  info as getOrderOutInfo
} from '@/service/api-scm/sellInOutWarehouse/sellOutWarehouse'

export {
  getList as getSellOrderId,
  info as getSellOrderInfo
} from '@/service/api-web/clientServe/sellManage/sellOrder'
