/**
 * @module api-web:organizational-settlement
 * @description 组织结算
 */
import service from '@/service'

/**
 * 分销商
 */
export function getSubShipperList(params) {
  return service['api-web']({
    url: '/public/data/getSubShipper',
    method: 'get',
    params
  })
}

/**
 * 货主
 */
export function getShipperOrParentOrgList(params) {
  return service['api-web']({
    url: '/public/data/getShipperOrParentOrg',
    method: 'get',
    params
  })
}

/**
 * 使用货主
 */
export function getWarehouseInfoByOrgList(params) {
  return service['api-web']({
    url: '/public/warehouse/getWarehouseInfoByOrg',
    method: 'get',
    params
  })
}

/**
 * 调出/调入仓库 查询调出/调入 货主
 */
export function getShipperByWarehouse(params) {
  return service['api-web']({
    url: '/public/data/getShipperByWarehouse',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * getWarehouseInfo4PickUp
 */
export function getWarehouseInfo4PickUp(params) {
  return service['api-web']({
    url: '/public/warehouse/getWarehouseInfo4PickUp',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
