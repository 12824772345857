/**
 * @module api-web:newProductImagesInfoController
 */
import service from '@/service'

/**
 * 新品上传图片   列表查询
 */
export function getList(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/getList',
    method: 'post',
    data
  })
}

/**
 * 新品上传图片   详情查询
 * @param {string} id
 */
export function info(id) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新品上传图片   新增
 */
export function create(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/save',
    method: 'post',
    data
  })
}

/**
 * 新品上传图片   修改
 */
export function update(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/update',
    method: 'post',
    data
  })
}

/**
 * @param {Array<number>} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/deleteById',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} id
 */
export function submit(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/submit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核
 * @param {{ids:Array<string>}} id
 */
export function completedAudit(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/completedAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 上传
 */
export function upload(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/upload',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    url: '/designApartment/newProductImagesInfoController/rejectAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getProductInfoNoAllPic(params) {
  return service['api-web']({
    url: '/public/product/getProductInfoNoAllPic',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function exportProductInfoNoAllPic(params) {
  return service['api-web']({
    url: '/public/product/exportProductInfoNoAllPic',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    saveAs: true
  })
}
