import service from '@/service'
/**
 * 查询产品包信息
 */
export function queryByProductCode(data) {
  return service['api-web']({
    url: '/data/product/packageInfo/log/queryByProductCode',
    method: 'post',
    data
  })
}

/**
 * 批量录入毛重
 */
export function submit(data) {
  return service['api-web']({
    url: '/data/product/packageInfo/log/submit',
    method: 'post',
    data
  })
}
