/**
 * @module api-web:filter-opts/index
 * @description apis : dropdown select options
 */
import service from '@/service'

export * from './sys'
export * from './hr'
export * from './data'
export * from './product'
export * from './warehouse'
export * from './logistics'
export * from './fin'

/**
 * @param {{keywords:string=}} params
 * @description 下拉选项：用户 (根据真实姓名 name 字段 模糊查询)
 */
export function getUserOpts(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params: {
      ...params,
      flag: 1
    }
  })
}
export function getUserPullList(params) {
  return service['api-web']({
    url: '/public/hr/getUserPullList',
    method: 'get',
    params
  })
}

/**
 * @param {{keywords:string=}} params
 * @description 下拉选项：用户 (根据真实姓名 name 字段 模糊查询)
 */
export function getUserNameOpts(params) {
  return getUserOpts(params)
}
/**
 * @param {params} 税率 code
 */
export function getTaxRateOpts(params) {
  return service['api-web']({
    url: '/public/getProductDefaultTax',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {params} 税率 id
 */
export function getTaxRateIdOpts(params) {
  return getTaxRateOpts(params)
}
/**
 * @param {params} 结算币种
 */
export function getSettlementCurrencyOpts(params) {
  return service['api-web']({
    url: '/public/getSettlementCurrency',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 计量单位下拉框
 * @param {{flag:(0|1)}} params
 */
export function getUnitOfMeasurementOpts(params) {
  return service['api-web']({
    url: '/public/getUnitOfMeasurement',
    method: 'get',
    params
  })
}

/**
 * 计量单位下拉框（只返回可用）
 */
export function getUnitOfMeasurementAvailableOpts(params = {}) {
  return service['api-web']({
    url: '/public/getUnitOfMeasurement',
    method: 'get',
    params: { ...params, flag: 1 }
  })
}

/**
 * 往来单位
 * type
 * name
 */
export function getDealingClientOpts(params) {
  return service['api-web']({
    url: '/public/data/getDealingClient',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @returns
 * @description 财务管理成本模块专属用
 */

export function getAccountCalendarAllOpts(params) {
  return service['api-web']({
    url: '/public/fin/getAccountCalendarAll',
    method: 'get',
    params
  })
}

/**
 * @param {{keywords:string=}} params
 * @description 下拉选项：用户 (根据真实姓名 name 字段 模糊查询)
 */
export function getEnableUserOpts(params) {
  return service['api-web']({
    url: 'public/findUserListSix',
    method: 'get',
    params: {
      ...params
    }
  })
}

/**
 * @param {{keywords:string=}} params
 * @description 履约标记 下拉选项
 */
export function getDemandOptions(params) {
  return service['api-web']({
    url: 'public/getLabelNameByInput',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
