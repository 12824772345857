import service from '@/service'

export { completeTasks } from '@/service/api-activiti/process/todo/handle'

export { activitiReject } from '@/service/api-scm'
export { activitiReject as activitiRejectOfSellOrder } from '@/service/api-web'
/**
 * @description 查询批量处理 单据列表
 * @param {*} data
 */
export function getOrderListByOrderType(data) {
  return service['api-activiti']({
    url: '/sys/process/task/getOrderListByOrderType',
    method: 'post',
    data
  })
}

// 解锁
// export function goUnlock(data) {
//   return service['api-activiti']({
//     url: '/public/unlock',
//     method: 'post',
//     data
//   })
// }
