/**
 * @module api-scm:inventoryProduct
 */
import service from '@/service'

/**
 * 库存总表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventorySummary/getList',
    method: 'get',
    params
  })
}

/**
 * 库存总表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventorySummary/export',
    method: 'post',
    data
  })
}
