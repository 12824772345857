/**
 * @module api-web:supplier
 */
import service from '@/service'

/**
 * 供应商列表查询
 * @param {{name:string,limit:number,page:number,supplierGroup:number,sort:string,dataStatus:number}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/supplierInformation/getList',
    method: 'get',
    params
  })
}

/**
 * 供应商 add
 */
export function create(data) {
  return service['api-web']({
    url: '/data/supplierInformation/saveAll',
    method: 'post',
    data
  })
}

/**
 * 供应商 update
 */
export function update(data) {
  return service['api-web']({
    url: '/data/supplierInformation/updateAll',
    method: 'post',
    data
  })
}

/**
 * 供应商 infoAll
 */
export function infoAll(id) {
  return service['api-web']({
    url: '/data/supplierInformation/infoAll',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/supplierInformation/infoAll',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/data/supplierInformation/reject',
    url: '/public/activiti/supplierInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array}} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/data/supplierInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 启用禁用
 * @param {(string|number)} id - id
 * @param {(0|1)} status - 0:禁用 1:启用
 */
export function enableDisable(data) {
  return service['api-web']({
    url: '/data/supplierInformation/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/data/supplierInformation/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @deprecated
 * @description 导出数据（直接下载文件）
 */
export function exportData(params) {
  return service['api-web']({
    url: '/data/supplierInformation/supplierInformation/export',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 导出数据（通过任务中心异步下载）
 */
export function exportDataViaTaskCenter(data) {
  return service['api-web']({
    url: '/data/supplierInformation/supplier/export',
    method: 'post',
    data
  })
}
/**
 * 供应商-删除
 * @param {{params:Array}} params
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/supplierInformation/deleteCreateStatusSupplier',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 供应商-反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/data/supplierInformation/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 供应商-反审核(批量)
 * @param {{ids:Array<string|number>}} data
 */
export function batchReverseAudit(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batchReverseAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 供应商-移动分组
 */
export function batchMoveSupplierGroup(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batchMoveSupplierGroup',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-web']({
    url: '/data/supplierInformation/manualDockingOfKingdee',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description   供应商资料导入Excel模板下载
 */
export function downloadExcel(data) {
  return service['api-web']({
    url: '/data/supplierInformation/downloadExcel',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description   供应商资料导入
 */
export function importSupplierInformation(data) {
  return service['api-web']({
    url: '/data/supplierInformation/importSupplierInformation',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description  查看供应商信息
 */
export function querySupplyInfo(params) {
  return service['api-web']({
    url: '/data/supplierInformation/querySupplyInfo',
    method: 'get',
    params
  })
}

/**
 * @description  查看供应商分组编号信息
 */
export function getSupplierGroupsInfo(params) {
  return service['api-web']({
    url: '/allPublic/getSupplierGroupInfo',
    crypt: { type: 'none' },
    method: 'get',
    params
  })
}

export function batchUploadProtocol(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batch/batchUploadProtocol',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * @description   营业执照导入
 */
export function saveProtocols(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batch/saveProtocols',
    method: 'post',
    data
  })
}
/**
 * 供应商批改字段
 * @param {data} data
 * @returns
 */
export function markingParams(data) {
  return service['api-web']({
    url: '/data/supplierInformation/markingParams',
    method: 'post',
    data
  })
}

/**
 * 批量启用
 * @param {data} data
 */
export function batchUpdateEnableStatus(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batchUpdateEnableStatus',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批量禁用
 * @param {data} data
 */
export function batchUpdateDisableStatus(data) {
  return service['api-web']({
    url: '/data/supplierInformation/batchUpdateDisableStatus',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description   供应品导入模板下载
 */
export function downloadExcelToSupplies(data) {
  return service['api-web']({
    url: '/data/supplierInformation/downloadExcelToSupplies',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description   供应品导入
 */
export function importSuppliesData(data) {
  return service['api-web']({
    url: '/data/supplierInformation/importSuppliesData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 根据编码和类型获取操作日志
 */
export function getLog(params) {
  return service['api-web']({
    url: '/data/supplierInformation/getLogsByCodeAndType',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
