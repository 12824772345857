<template>
  <el-skeleton
    :rows="2"
    :count="1"
    animated
    style="width: 100%"
    :loading="CustomQueryTabLoading"
  >
    <div id="CustomQueryTab" class="CustomQueryTab">
      <div id="preSetSolutionListContainer" class="preSetSolutionListContainer">
        <el-button
          v-for="item in preSetSolutionListT"
          :key="item.id"
          :class="[
            'search_btn',
            item.id === nowClickId ? 'search_btn_active' : ''
          ]"
          :title="item.solutionName"
          @click="goSearch(item)"
        >
          {{ item.solutionName }}
        </el-button>
        <div v-if="preSetSolutionListEnd.length > 0" class="more_btn_container">
          <el-button>
            更多
            <i class="el-icon-caret-bottom" />
          </el-button>
          <div class="more_manage_list_container">
            <el-button
              v-for="item in preSetSolutionListEnd"
              :key="item.id"
              :class="[
                'search_btn',
                'search_btn_end',
                item.id === nowClickId ? 'search_btn_active' : ''
              ]"
              :title="item.solutionName"
              @click="goSearch(item)"
            >
              {{ item.solutionName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-skeleton>
</template>
<script>
import { getCustomQuery, addCustomQuery } from '@/service/api-scm/index.js'
export default {
  name: 'CustomQueryTab',
  props: {
    customQueryTabKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      defaultSolution: [],
      preSetSolutionList: [],
      preSetSolutionListStart: [],
      preSetSolutionListEnd: [],
      nowClickId: null,
      buttonHiddenIndex: null,
      CustomQueryTabLoading: false
    }
  },
  computed: {
    CustomQueryTabWidth() {
      const div = document.getElementById('CustomQueryTab')
      return div.offsetWidth
    },
    preSetSolutionListContainerWidth() {
      const div = document.getElementById('preSetSolutionListContainer')
      return div.offsetWidth
    },
    preSetSolutionListT() {
      if (this.preSetSolutionListStart.length > 0) {
        return this.preSetSolutionListStart
      } else {
        return this.preSetSolutionList
      }
    }
  },
  created() {
    this.getCustomQueryList()
    // this.addCustomQuery()
  },
  mounted() {},
  methods: {
    async getCustomQueryList() {
      this.CustomQueryTabLoading = true
      // 数据清洗
      this.preSetSolutionList = []
      this.preSetSolutionListStart = []
      this.preSetSolutionListEnd = []
      // 载荷
      const params = {
        pageType: this.customQueryTabKey
      }
      const { code, data } = await getCustomQuery(params)
      if (code === 200) {
        const { defaultSolution, preSetSolutionList } = data
        this.preSetSolutionList = preSetSolutionList
        if (defaultSolution) {
          this.preSetSolutionList.unshift(defaultSolution)
        }
        this.preSetSolutionList.unshift({
          solutionName: '初始方案',
          solutionJson: null,
          pageType: '',
          isShared: 0,
          isDefault: 0,
          id: -1 // -1保证与正式方案不重合
        })

        const isDefaultList = this.preSetSolutionList.filter(
          (item) => item.isDefault === 1
        )
        if (isDefaultList.length > 0) {
          this.nowClickId = isDefaultList[0].id
          const { solutionJson } = isDefaultList[0]
          const solutionJsonPares = JSON.parse(solutionJson)
          this.$emit('tab-search-event-default', solutionJsonPares)
        } else {
          this.nowClickId = this.preSetSolutionList[0].id
          this.$emit('tab-search-event', {
            id: this.nowClickId,
            isCreated: true
          })
        }

        // 动态宽度适应
        this.$nextTick(() => {
          this.getIndexHiddenButton()
        })
      }
      this.CustomQueryTabLoading = false
    },
    async addCustomQuery() {
      // 载荷
      const params = {
        pageType: this.customQueryTabKey,
        id: null,
        isDefault: 1,
        isShared: 0,
        solutionJson: {
          purchaseOrganization: [],
          settlementMode: [],
          date: [],
          logisticsAbnormal: null,
          isReconciliation: null,
          isMonthly: null,
          cancellationType: null,
          dockingFlag: null,
          pullFlag: 1,
          sort: '',
          keywords: '',
          page: 1,
          limit: 20,
          purchaseUserName: '',
          documentCodeList: [],
          productCodeList: [],
          productName: '',
          supplierName: '',
          documentType: 1,
          orderProgressPurchase: null,
          saleCode: '',
          paymentDaysNote: '',
          documentStatus: '',
          auditTime: '',
          warehouseList: null,
          inWarehouseStatusList: null,
          applyCode: ''
        },
        solutionName: '测试查询5'
      }
      params.solutionJson = JSON.stringify(params.solutionJson)
      await addCustomQuery(params)
    },
    goSearch(item) {
      const { solutionJson, id } = item
      this.nowClickId = id
      const solutionJsonPares = JSON.parse(solutionJson)
      this.$emit('tab-search-event', { id, solutionJsonPares })
    },
    getAllButtonsWidth() {
      // 获取 div 容器
      const container = document.getElementById('preSetSolutionListContainer')
      // 获取所有的 button 元素
      const buttons = container.getElementsByTagName('button')
      // 计算所有 button 元素的宽度总和
      let totalWidth = 0
      for (const button of buttons) {
        totalWidth += button.offsetWidth // 获取每个按钮的宽度
      }
      return totalWidth
    },
    // 判断第几个button 应该去隐藏
    getIndexHiddenButton() {
      // 总宽度 CustomQueryTabWidth
      const container = document.getElementById('preSetSolutionListContainer')
      // 获取所有的 button 元素
      const buttons = container.getElementsByTagName('button')

      // 计算所有 button 元素的宽度总和
      let totalWidth = 0
      for (const index in buttons) {
        const button = buttons[index]
        totalWidth += button.offsetWidth
        // 300为冗余宽度
        if (totalWidth + 300 > this.CustomQueryTabWidth) {
          this.buttonHiddenIndex = index
          console.log('当前需要隐藏的序列：', this.buttonHiddenIndex)
          console.log('宽度为：', totalWidth)
          this.preSetSolutionListStart = this.preSetSolutionList.slice(
            0,
            this.buttonHiddenIndex
          )
          this.preSetSolutionListEnd = this.preSetSolutionList.slice(
            this.buttonHiddenIndex
          )
          return
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.CustomQueryTab) {
  padding: 10px 5px 0 5px;
  background-color: #fff;
}
.search_btn {
  :deep(span) {
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.search_btn_active {
  border-color: #409eff;
  color: #409eff;
}
.preSetSolutionListContainer {
  display: inline-block;
  transition: width 1s ease; /* 添加过渡效果 */
}
.more_btn_container {
  position: relative;
  float: right;
  margin-left: 10px;
}
.more_manage_list_container {
  top: 29px;
  position: absolute;
  width: 140px;
  height: 450px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 99;
  box-shadow: #000;
  display: none;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  opacity: 0;
  pointer-events: none; /* 禁用鼠标事件，防止它影响其他元素 */
  transition: opacity 0.5s ease;
}
.more_btn_container:hover .more_manage_list_container {
  display: block; /* 鼠标悬停时显示 */
  opacity: 1;
  pointer-events: auto; /* 启用鼠标事件 */
}
.search_btn_end {
  margin: 0;
  margin-top: 5px;
}
/*:deep(.el-skeleton).is-animated .el-skeleton__item {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #1a7ef150 63%);
  background-size: 400% 100%;
  animation: el-skeleton-loading 1.4s ease infinite;
}*/
</style>
