/**
 * @module utils/get-page-title
 */

import defaultSettings from '@/settings'

const title = defaultSettings?.title || ''

const env = process?.env?.VUE_APP_ENV_TAB_NAME || '' // only for local dev

/**
 * @param {string} pageTitle
 * @returns {string} 页面标题：浏览器标签标题
 */
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${env}${pageTitle} - ${title}`
  }
  return `${env}${title}`
}

export default getPageTitle
