/**
 * @module store/modules/sysTree
 * @description 系统树
 */
import Vue from 'vue'

import poll from '@/utils/poll'
import { NETWORK_TIMEOUT } from '@/constants'
import * as webSysTree from '@/service/api-web/sys-tree'
import * as activitiSysTree from '@/service/api-activiti/sys-tree'
// import * as scmSysTree from '@/service/api-scm/sys-tree'

import { isArray, isString, isPlainObject } from '@/utils/validate'
import { uppercaseFirst } from '@/utils/string'
import { unflattenSync } from '@/utils/tree'
const sysTreeApi = {
  web: webSysTree,
  activiti: activitiSysTree
  // scm: scmSysTree
}
/**
 * @returns {Record<Key,Array>}
 */
const state = () => ({
  /**
   * @description 系统管理 - 部门树
   */
  department: [],

  /**
   * @description 系统管理 - 职位树
   */
  job: [],

  /**
   * @description 资料管理 - 客户分组树
   */
  customer: [],

  /**
   * @description 资料管理 - 商品树
   */
  goods: [],

  /**
   * @description 资料管理 - 供应商树
   */
  supplier: [],

  /**
   * @description 系统管理 - 流程管理 - 分类树
   */
  processCategory: [],

  /**
   * @description 财务->总账->资料->费用项目分组树
   */
  constitem: [],
  /**
   * @description 财务->总账->资料->凭证模板分组树
   */
  vouchertemplate: [],
  /**
   * @description 系统对接->金蝶对接中心
   */
  dockingKingdee: [],
  /**
   * @description 系统对接->第三方仓库对接中心
   */
  dockingThirdParty: [],
  /**
   * @description 鑫方盛产品列表
   */
  xfsProduce: []
})

const getters = {}

const mutations = {
  SET_TREE: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    } else {
      console.error(
        '【getTree】losed key:' + key + ' for "SET_TREE" that on state'
      )
    }
  },
  SET_FETCH_COUNT_INCREASE(state, { key, fetchedFlagKey }) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      if (Object.prototype.hasOwnProperty.call(state, fetchedFlagKey)) {
        state[fetchedFlagKey] = state[fetchedFlagKey] + 1
      } else Vue.set(state, fetchedFlagKey, 1)
    } else {
      console.error(
        '【getTree】losed key:' +
          key +
          ' for "SET_FETCH_COUNT_INCREASE" that on state'
      )
    }
  },
  SET_FETCH_COUNT_DECREASE(state, { fetchedFlagKey }) {
    if (Object.prototype.hasOwnProperty.call(state, fetchedFlagKey)) {
      state[fetchedFlagKey] = state[fetchedFlagKey] - 1
    } else {
      console.error(
        '【getTree】losed fetchedFlagKey:' +
          fetchedFlagKey +
          ' for "SET_FETCH_COUNT_DECREASE" that on state'
      )
    }
  }
}
const actions = {
  /**
   * @typedef {('department'|'job'|'goods'|'supplier'|'customer'|'processCategory')} Key
   *
   * @typedef {Object} Match
   * @property {string} [value='id']
   * @property {string} [label='label']
   *
   * @typedef {Object} GetTreeActionPayload
   * @property {'web'|'activiti'|'scm'} [service=web] - service 服务名
   * @property {Key} key - key(必传)：用于指定选项API
   * @property {Match=} match 用于匹配键值
   * @property {boolean=} [force=true] 是否绕开状态库强制远程获取
   * @property {{parentIdKey:string,idKey:string,childrenKey:string}=} convert 如需将扁平的数组转换成树结构数组
   *
   * @param {import('vuex').ActionContext} actionContext
   * @param {GetTreeActionPayload} payload
   * @returns {Promise<Array|undefined>}
   * @description 获取系统各种树列表
   *
   */
  async getTree({ state, commit, rootGetters }, payload) {
    try {
      const { service = 'web', key, match, force = true, convert } = payload
      if (!key || !isString(key)) {
        console.error('【getTree】illegal "key" for "getTree"')
        return []
      }

      if (force !== true) {
        const cached = getCached(state, key)
        if (cached === false) return []
        if (isArray(cached)) return cached
      }

      /**
       * 前置拦截，释放请求池压力，via poll
       */
      const fetchedFlagKey = getFetchedFlagKey({
        service,
        key,
        match
      })

      if (state[fetchedFlagKey] > 0) {
        const cached = getCached(state, key)
        if (cached === false) return []
        if (isArray(cached)) return cached

        const polled = await poll({
          target: state,
          nested: [key, length],
          timeout: NETWORK_TIMEOUT.request
        })
        if (polled > 0) {
          const cached = getCached(state, key)
          if (cached === false) return []
          if (isArray(cached)) return cached
        }
      }
      /**
       * apis
       * @type {Record<string,Promise<import('axios').AxiosResponse>>}
       * @description 服务划分下的 service/xxx/sys-tree.js
       */
      const apis = sysTreeApi[service]
      if (typeof apis !== 'object') {
        console.error('【getTree】losed "apis" for "getTree":', service)
        return []
      }
      /**
       * apiName
       * @type {string}
       * @description 在 api 中定义的请求树列表的 request name
       */
      const apiName = `get${uppercaseFirst(key)}Tree`
      if (typeof apis[apiName] !== 'function') {
        console.error('【getTree】losed "apiName" for "getTree":', apiName)
        return []
      }

      commit('SET_FETCH_COUNT_INCREASE', { key, fetchedFlagKey })
      const { data = [] } = await apis[apiName]().finally(() =>
        commit('SET_FETCH_COUNT_DECREASE', { fetchedFlagKey })
      )
      if (!isArray(data)) {
        console.error('【getTree】responsed data not array')
      }
      const tree =
        !match ||
        (
          isPlainObject(match) &&
          Object.values(match).filter((v) => isString(v) && v.trim())
        ).length > 0
          ? mapTreeByMatch(data, match)
          : isArray(data)
          ? data
          : []
      console.groupCollapsed(`【getTree】${key} (remote)`)
      console.log(`${key} tree list:`, JSON.parse(JSON.stringify(tree)))
      console.groupEnd()
      commit('SET_TREE', {
        key,
        value:
          isString(convert?.parentIdKey) &&
          convert.parentIdKey.trim() &&
          isString(convert?.idKey) &&
          convert.idKey.trim()
            ? unflattenSync([{ id: 0, label: '全部' }, ...tree], convert)
            : tree
      })
      return state[key]
    } catch (error) {
      console.error('【getTree】catched error', error)
      return []
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function getFetchedFlagKey({ service, key, match }) {
  return JSON.stringify({ service, key, match })
}

/**
 * @param {*} state
 * @param {*} key
 * @returns {(Boolean|Array)}
 */
function getCached(state, key) {
  const opts = state[key]
  if (isArray(opts)) {
    if (opts.length > 0) {
      // console.groupCollapsed(`【getTree】${key} (local)`)
      // console.log(`${key} match:`, match)
      // console.log(`${key} options:`, JSON.parse(JSON.stringify(opts)))
      // console.groupEnd()
      return opts
    } else return true
  } else {
    console.error('【getTree】not defined "key" for "getTree"', key)
    return false
  }
}

/**
 * @param {Array} data list from service
 * @param {Match}} match 字段匹配
 * @returns {Array} - mapped tree array
 */
function mapTreeByMatch(tree, match) {
  const { value = 'id', label = 'label' } = match || {}

  function treeMap(node) {
    const hasChild = node.children && node.children.length > 0
    return {
      ...node,
      label: node[label],
      value: node[value],
      children: hasChild ? node.children.map((i) => treeMap(i)) : null
    }
  }
  const _tree = tree
    .filter((v) => isPlainObject(v))
    .map((node) => treeMap(node))
  return _tree
}
