<template>
  <div>
    <u-table
      ref="table"
      v-loading="tableLoading"
      :class="['vtable', tableKey]"
      border
      fixed-columns-roll
      :tree-config="{
        ...treeConfig,
        load: _loadChildMethod
      }"
      big-data-checkbox
      beautify-table
      use-virtual
      row-id="id"
      row-key="id"
      :header-cell-class-name="getHeaderClassName"
      :cell-class-name="getCellClassName"
      :row-style="getRowStyle"
      v-bind="proxyAttrs"
      v-on="proxyListener"
      @header-dragend="onHeaderDragend"
      @select="onSelect"
      @select-all="onSelect"
      @cell-mouse-enter="handleRowMouseEnter"
      @cell-mouse-leave="handleRowMouseLeave"
      @sort-change="onSortChange"
      @row-contextmenu="onRowContextMenu"
      @filter-change="filterChange"
    >
      <u-table-column
        v-for="headerConfig in tableHeader"
        :key="headerConfig.type ?? headerConfig.prop"
        :render-header="(h, e) => renderHeader(h, e, headerConfig)"
        v-bind="handleOtherHeaderConfig(headerConfig)"
        :filters="headerConfig.filters ? headerConfig.filters : undefined"
        :reserve-selection="reserveSelection"
      >
        <template
          v-if="isNotIndexOrCheckbox(headerConfig)"
          #default="slotParams"
        >
          <svg-icon
            v-if="
              headerConfig.autoCopy &&
              hoveredRow === slotParams.row &&
              slotParams.row[headerConfig.prop]
            "
            icon-class="i-copy-hollow"
            style="cursor: pointer"
            class="copySvg"
            @click.stop="goHandleCopy(slotParams.row[headerConfig.prop])"
          />
          <slot
            v-if="headerConfig.isComp"
            :name="headerConfig.prop"
            v-bind="slotParams"
          />

          <span
            v-else-if="
              (typeof slotParams.row[headerConfig.prop] === 'string' &&
                slotParams.row[headerConfig.prop] !== '' &&
                slotParams.row[headerConfig.prop].trim()) ||
              typeof slotParams.row[headerConfig.prop] === 'number'
            "
            :class="[headerConfig.prop === 'index' ? 'customer-index' : '']"
          >
            {{
              typeof headerConfig.formatter === 'function'
                ? headerConfig.formatter(slotParams.row)
                : slotParams.row[headerConfig.prop]
            }}
          </span>
          <span v-else class="table-na-placeholder">
            {{
              typeof headerConfig.formatter === 'function'
                ? headerConfig.formatter(slotParams.row)
                : '⏤'
            }}
          </span>
        </template>
      </u-table-column>

      <slot name="operation" />
    </u-table>
    <!-- table右键菜单 -->
    <div
      v-if="menuVisible"
      ref="contextMenu"
      :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
      class="task-context-menu"
    >
      <ul>
        <li
          v-for="(item, index) in menuList"
          :key="index"
          @click="item.options"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createUniqueString } from '@/utils/string'
import listHeaderCache from '@/storage/local/listHeader'
import { isArray } from 'lodash'
import dragColumnMixin from '@/mixins/table/dragColumn'
import { debounce } from 'lodash'
import { handleCopy } from '@/utils/clipboard'
export default {
  name: 'VirtualTable',
  mixins: [dragColumnMixin],

  props: {
    tableKey: {
      type: String,
      required: true
    },

    border: {
      type: Boolean,
      default: false
    },

    header: {
      type: Array,
      default: () => [],
      required: true
    },
    tableData: {
      type: Array,
      default: () => [],
      required: false
    },

    size: {
      type: String,
      default: ''
    },

    treeConfig: {
      type: Object,
      default: () => ({})
    },

    treeNode: {
      type: String,
      default: 'index'
    },

    // TODO 目前只用于表格拖拽
    showIndex: {
      type: Boolean,
      default: true
    },
    showCheckboxInChildrenRow: {
      type: Boolean,
      default: false
    },
    reserveSelection: {
      type: Boolean,
      default: false
    },
    rightClickCopyRowColumn: {
      type: Boolean,
      default: false
    },
    remoteHeader: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tableLoading: false,
      hoveredRow: null,
      menuList: [
        {
          name: '复制整行',
          options: this.copyRow
        },
        {
          name: '复制整列',
          options: this.copyColumn
        }
      ],
      menuVisible: false,
      menuPosition: { x: 0, y: 0 },
      columnData: null,
      rowData: null,
      filterCache: {}, // 用于缓存已经请求的 filters 数据
      filterColumnIndexList: []
    }
  },

  computed: {
    $table() {
      return this.$refs['table']
    },

    uuTableKey() {
      return this.tableKey
        ? `base-table:${createUniqueString()}`
        : `base-table:${this.tableKey}:${createUniqueString()}`
    },

    tableHeader() {
      const { key, setup } = listHeaderCache
      let result = setup(key.call(this, this.tableKey), this.mixinColumns)

      if (this.remoteHeader?.length > 0) {
        result = result.filter((local) => {
          if (local.type && !local.label) return true

          return this.remoteHeader.find(
            (remote) => local.label + local.prop === remote.label + remote.prop
          )?.isShow
        })
      }

      /**
       * 由于当筛选条件是从后端获取的时候初始化一个空数组，后续请求回来应该覆盖之前的值但是再请求结束后表头的渲染就结束了无法赋值
       * 所以这里在表格内部请求接口
       */
      // 优化: 缓存已经请求的 filter 数据
      result.map(async (headerConfig, index) => {
        if (typeof headerConfig.filters === 'function') {
          const filterKey = headerConfig.prop // 使用字段名或唯一标识符作为缓存键
          if (!this.filterCache[filterKey]) {
            this.filterCache[filterKey] = await this.filterConfig(
              headerConfig.filters
            ) // 获取并缓存 filter 数据
          }

          headerConfig.filters = this.filterCache[filterKey]
        }
        // 获取所有需要过滤的列
        if (headerConfig.filters) {
          if (
            this.filterColumnIndexList.findIndex(
              (item) => item.prop === headerConfig.prop
            ) === -1
          ) {
            this.filterColumnIndexList.push({
              prop: headerConfig.prop
            })
          }
        }
        return headerConfig
      })

      return Array.isArray(result) ? result : []
    },

    proxyAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { treeConfig, maxHeight, height, data, ...rest } = this.$attrs

      if (maxHeight) {
        return {
          ...rest,
          maxHeight: this.getTableHeight(maxHeight, 700)
        }
      }
      return {
        ...rest,
        height: this.getTableHeight(height)
      }
    },

    proxyListener() {
      // eslint-disable-next-line no-unused-vars
      const { selectAll, select, ...rest } = this.$listeners

      return rest
    },

    displayOfCheckboxInChildrenRow() {
      return this.showCheckboxInChildrenRow ? 'inline-block' : 'none'
    },
    includeChildTableData() {
      return this.getChildren(this.tableData)
    }
  },
  mounted() {
    document.addEventListener('click', this._hideContextMenu)
    // 监听点击事件，检查是否点击在表格外部
    // document.addEventListener('mousedown', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this._hideContextMenu)
    // document.removeEventListener('mousedown', this.handleClickOutside)
  },

  methods: {
    getTableHeight(height, defaultHeight = 400) {
      if (typeof height === 'number') {
        return height
      }

      const result = parseInt(height?.replace('px', ''))

      return result || defaultHeight
    },

    _loadChildMethod(row, resolve) {
      // 原表格load方法不支持async/await写法，故在此转化
      new Promise((fetchData) => {
        this.treeConfig.load(row, fetchData)
      }).then((data) => {
        resolve(data)
      })
    },

    handleOtherHeaderConfig(config) {
      // eslint-disable-next-line no-unused-vars
      const { type, prop, formatter, fixed, filters, ...rest } = config

      // 处理序号、复选框列
      const fieldName = type ? 'type' : 'prop'
      const fieldValue = config[fieldName]

      const _config = {
        [fieldName]: fieldValue,
        ...rest
      }

      if (filters) {
        _config.filterMethod = (value, row, column) => {
          const property = column['property']
          return row[property] === value
        }
      }

      // 处理formatter
      if (typeof formatter === 'function') {
        // 为了兼容当前listHeader中formatter的写法
        _config.formatter = (row, column, cellValue, index) => {
          const result = config.formatter({ ...row, cellValue }, column, index)

          return result
        }
      } else if (this.isNotIndexOrCheckbox(config)) {
        _config.formatter = (_, __, cellValue) =>
          cellValue == null ? '⏤' : cellValue
      }

      // 处理固定列
      if (['selection'].includes(fieldValue)) {
        _config.fixed = 'left'
      } else if (fixed) {
        _config.fixed = fixed
      }

      // 处理展开列
      if (this.treeConfig.load && fieldValue === this.treeNode) {
        _config['tree-node'] = true
      }

      // 处理宽度
      if (typeof _config.width === 'number') {
        _config['width'] = _config.width + 'px'
      }

      return _config
    },

    async filterConfig(config) {
      const data = await config()
      return data
    },

    isNotIndexOrCheckbox(config) {
      const isIndexOrCheckbox =
        config.prop === 'index' || config.type === 'selection'
      return !isIndexOrCheckbox
    },

    reloadData(data) {
      /**
       * @description: 由于该表格存在缺陷，表格表头筛选时,有些字段子节点后端未给数据或者给的数据为空，导致筛选后只能查到父节点点，子节点点数据会丢失
       *  且重新展开的时候也查不到子节点点数据，故此处将所有需要过滤的字段的子节点默认都赋值上父节点的数据，解决此问题
       */

      const filterHeader = this.tableHeader.filter(
        (item) => item.filters && item.filters.length > 0
      )
      if (filterHeader.length !== 0) {
        if (this.treeConfig) {
          data.forEach((item) => {
            if (
              item[this.treeConfig.children] &&
              item[this.treeConfig.children].length > 0
            ) {
              filterHeader.forEach((i) => {
                item[this.treeConfig.children].forEach((j) => {
                  j[i.prop] = item[i.prop]
                })
              })
            }
          })
        }
      }

      if (isArray(data)) {
        this.$table.reloadData(data)
      } else {
        console.error(`VirtualTable: 'data类型不是数组'`)
      }
    },

    getHeaderClassName({ column }) {
      const base = 'vtable-header-cell'

      // 拖拽行使用
      if (column.type === 'selection' || column.property === 'index') {
        return `${base} ignore-elements`
      } else {
        return `${base} able-elements`
      }
    },

    getCellClassName({ row }) {
      let className = ''

      if (row.pl_table_level > 0) className = 'isChildInTreeTable'

      return className
    },

    onSelect(selection, row) {
      let _selection = selection
      console.log('_selection', _selection)

      if (!this.showCheckboxInChildrenRow) {
        // 全选时，组件默认会选上展开子级，因此此处做一道过滤，只返回父级
        _selection =
          selection?.filter((item) => item.pl_table_level === 0) ?? []
      }

      this.$emit('select', _selection, row)
    },

    getRowStyle({ row }) {
      if (row.color) {
        return {
          backgroundColor: row.color
        }
      }
    },
    onHeaderDragend(newWidth, oldWidth, column, event) {
      // this.$_dropSortable()

      // 限制最小宽度为1个中文字符和省略号...
      if (newWidth <= 32) {
        newWidth = 32
      }

      column.width = newWidth
      column.realWidth = newWidth

      this.$emit('header-dragend', { newWidth, oldWidth, column, event })

      const { key, updateColumnWidth } = listHeaderCache
      const _key = key.call(this, this.tableKey)
      debounce(function () {
        updateColumnWidth(_key, column, newWidth)
      }, 400)()
    },

    handleDragEnd() {
      // 拖拽结束后将所有列的过滤值重新赋值
      this.$nextTick(() => {
        this.filterColumnIndexList.forEach((item) => {
          this.$refs['table']
            .getTableColumn()
            .find((i) => i.property === item.prop).filteredValue =
            item.filteredValue
        })
      })

      this.$emit('handleDrag-end')
    },
    // 当筛选框被点击选中筛选时记录筛选状态是否筛选
    filterChange(filters) {
      const key = Object.keys(filters)[0]

      this.$set(
        this.$refs['table'].getTableColumn().find((item) => item.id === key),
        'isFilter',
        filters[key].length > 0
      )
      // console.log('filters', filters)
      // console.log('this.$refs', this.$refs['table'].getTableColumn())
    },
    // 监听点击除了下拉框页面其他地方，关闭筛选框
    handleClickOutside(event) {
      const filterDropdown = document.querySelector('.el-table-filter')

      if (filterDropdown && !filterDropdown.contains(event.target)) {
        // console.log('this.$refs', event.target, filterDropdown)
        this.$refs['table'].getTableColumn().forEach((item) => {
          item.filteredValue = item.isFilter ? item.filteredValue : []
        })
      }
    },

    renderHeader(h, e, config) {
      if (typeof config?.tooltipContent === 'function') {
        return config.tooltipContent(e, config)
      } else {
        return h(
          'div',
          { class: 'table-head', style: { width: '98%', margin: '0 auto' } },
          [e.column.label]
        )
      }
    },
    /* 鼠标经过*/
    handleRowMouseEnter(row, column, cell, event) {
      this.hoveredRow = row
    },
    /* 鼠标离开*/
    handleRowMouseLeave() {
      this.hoveredRow = null
    },
    /* 复制*/
    goHandleCopy(txt) {
      handleCopy(txt)
    },

    clearSelection() {
      this.$refs.table.clearSelection()
    },

    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row)
    },

    getCheckboxRecords() {
      return this.$refs.table.getCheckboxRecords()
    },
    onSortChange(column) {
      this.$emit('sort-change', column)
    },
    sort(prop, order) {
      this.$refs.table.sort(prop, order)
    },
    onRowContextMenu(row, column, event) {
      if (!this.rightClickCopyRowColumn) return
      this.menuVisible = true
      this.menuPosition = { x: event.clientX + 10, y: event.clientY }
      // 阻止默认浏览器右键事件
      if (event.button === 2) event.preventDefault()
      this.rowData = row
      this.columnData = this.tableHeader.find(
        (item) => item.label === column.label
      )
    },
    copyRow() {
      console.log('this.rowData', this.rowData)
      console.log('this.$slots', this.$scopedSlots)
      // console.log(this.tableHeader, 'this.tableHeader')
      const copyRowData = this.tableHeader
        .filter(
          (item, index) =>
            item.prop !== 'operation' && item.type !== 'selection'
        )
        .map((item, index) => {
          if (item.formatter) {
            return item.formatter({ [item.prop]: this.rowData[item.prop] })
          } else if (item.isComp) {
            const slot = this.$scopedSlots[item.prop]
            const slotContent = slot({ row: this.rowData, column: item })
            // console.log('slotContent', slotContent)
            let cellValue
            // 插槽且插槽内部为组件
            if (!slotContent[0].text) {
              cellValue = slotContent.map(
                (vnode) =>
                  vnode.context.$refs[`${item.prop}_${this.rowData.index}`].$el
                    .innerText
              )
              // console.log('cellValue', cellValue)
            } else {
              // 插槽可直接获取Dom
              cellValue = slotContent.map((vnode) => vnode.text)
            }

            return cellValue
          } else {
            return this.rowData[item.prop]
          }
        })
        .join('\t')
      this._copyToClipboard(copyRowData)
    },
    copyColumn() {
      this.$nextTick(() => {
        const copyColumnData = this.includeChildTableData
          .map((item, index) => {
            if (this.columnData.formatter) {
              return this.columnData.formatter({
                [this.columnData.prop]: item[this.columnData.prop]
              })
            } else if (this.columnData.isComp) {
              const slot = this.$scopedSlots[this.columnData.prop]
              const slotContent = slot({ row: item })
              // console.log('slotContent', slotContent)
              let cellValue
              // 插槽且插槽内部为组件
              if (!slotContent[0].text) {
                cellValue = slotContent.map(
                  (vnode) =>
                    vnode.context.$refs[`${this.columnData.prop}_${item.index}`]
                      ?.$el?.innerText
                )
                // console.log('cellValue', cellValue)
              } else {
                // 插槽可直接获取Dom
                cellValue = slotContent.map((vnode) => vnode.text)
              }

              return cellValue
            } else {
              return item[this.columnData.prop]
            }
          })
          .join('\n')
        this._copyToClipboard(copyColumnData)
      })
    },
    // 复制逻辑
    _copyToClipboard(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      this.menuVisible = false
      try {
        document.execCommand('copy')
        this.$message.success('复制成功')
      } catch (err) {
        this.$message.error('复制失败')
        console.error('复制失败：', err)
      }
      document.body.removeChild(textArea)
    },
    // 递归获取子节点
    getChildren(data) {
      const arr = []
      for (const item of data) {
        arr.push(item)
        if (item[this.treeConfig.hasChildren]) {
          arr.push(...item[this.treeConfig.children])
        }
      }
      return arr
    },
    _hideContextMenu(event) {
      // 如果点击区域不在菜单内，隐藏菜单
      if (
        this.$refs.contextMenu &&
        !this.$refs.contextMenu.contains(event.target)
      ) {
        this.menuVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vtable {
  :deep(.vtable-header-cell) {
    .cell {
      font-weight: bold;
      color: #00182e;
      overflow: hidden;
      min-width: 32px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background-color: #d7effb !important;
    }
  }

  :deep(.isChildInTreeTable) {
    .cell {
      .el-checkbox {
        display: v-bind(displayOfCheckboxInChildrenRow);
      }
    }
  }
}
.copySvg {
  margin-right: 2px;
  cursor: pointer;
}
.task-context-menu {
  position: fixed;
  background-color: white;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  border-radius: 3px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    li {
      padding: 8px 12px;
      cursor: pointer;
      &:hover {
        background-color: #dcf1ff;
      }
    }
  }
}
::v-deep(.vtable) {
  .vtable-header-cell .cell {
    display: flex;
    align-items: center;
    .table-head {
      width: auto !important;
      margin: 0 !important;
    }
  }
}
::v-deep(.el-table__column-filter-trigger) {
  i {
    font-size: 15px;
    color: #000;
    transform: unset;
    margin-left: 3px;
  }
}
</style>
