/**
 * @module api-web:sys-tree
 * @description apis: tree dir lists
 */
import service from '@/service'

/**
 * 系统树：部门
 */
export function getDepartmentTree() {
  return service['api-web']({
    url: '/public/sys/getDeptListTree',
    method: 'get'
  })
}

/**
 *  系统树：职位
 */
export function getJobTree() {
  return service['api-web']({
    url: '/public/sys/getJobListTree',
    method: 'get'
  })
}

/**
 *  系统树：客户分组
 */
export function getCustomerTree(params) {
  return service['api-web']({
    url: '/data/clientGroups/getListTree',
    method: 'get',
    params
  })
}
/**
 *  系统树：商品
 */
export function getGoodsTree() {
  return service['api-web']({
    url: '/data/product/Category/getTreeList',
    method: 'get'
  })
}

/**
 *  系统树：供应商分组
 */
export function getSupplierTree(params) {
  return service['api-web']({
    url: '/data/supplierGroups/getListTree',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 财务->总账->资料->费用项目分组树
 */
export function getConstitemTree(params) {
  return service['api-web']({
    url: '/public/fin/getFinCostItemGroup',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getVouchertemplateTree(params) {
  return service['api-web']({
    url: '/public/fin/getVoucherTemplateTreeVO',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 系统对接->金蝶对接中心
 */
export function getDockingKingdeeTree() {
  return service['api-web']({
    url: '/dc/kingdee/getListTree',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 系统对接->第三方仓库对接中心
 */
export function getDockingThirdPartyTree() {
  return service['api-scm']({
    url: '/dc/thirdParty/warehouse/getListTree',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 资料管理->鑫方盛
 */

export function getXfsProduceTree(params) {
  return service['api-web']({
    url: '/data/xfsProduct/Information/getTreeList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
