/**
 * @module api-scm:buyInAndOutWarehouse/buyAfterManage
 */
import service from '@/service'

/**
 * 售后申请   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * outWarehouseCode:string,
 * productCode:number,
 * productName:string,
 * purchaseApplyReturnCode:string,
 * supplierCode:string,
 * date:Array<string>
 * }} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/get/getList',
    method: 'post',
    data
  })
}

/**
 *
 * @param {(string|number)} code
 * @description 树形表格下拉
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/get/getPullList',
    method: 'get',
    params: { purchaseApplyReturnCode: code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getSeleteOrderList(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/getPullList',
    method: 'get',
    params
  })
}

/**
 * @param {object} data
 * @description 新增
 */

export function create(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/save',
    method: 'post',
    data
  })
}

/**
 * @param {object} data
 * @description 更新
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/update',
    method: 'post',
    data
  })
}
/**
 * @param {Array<number|string>} ids
 * @description 提交
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehousePurchaseApplyReturn/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 详情
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehousePurchaseApplyReturn/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据采购入库单编码获取入库记录
 * @param {params} params - 入库单编号
 */
export function getRecordByEntryCode(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/get/getRecordByEntryCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 导出
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/export',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false
  })
}
