import { isPlainObject } from '@/utils/validate'
import { getSyncAsyncResult, getSyncAsyncReturns } from '@/utils'
export default {
  /**
   * 下推
   */
  name: 'pushDownMixin',
  data() {
    return {
      pushDownVisible: false,
      pushDownOptions: [],
      documentTypeOpts: [],
      returnTypeOpts: [],
      logisticsCompanyOpts: []
    }
  },
  computed: {
    pushDownBtn() {
      const btns = this.buttonGroup.filter(
        (item) => item.evtName === '下推' || item.label === '下推'
      )
      /**
       * TODO： 设计缺陷，暂只支持一个下推按钮
       */
      return btns[0] || {}
    },
    hasPushDownBtn() {
      if (this.pushDownBtn.noDrawer) {
        return false
      }

      const has = !!(
        isPlainObject(this.pushDownBtn) &&
        Object.keys(this.pushDownBtn).length > 0
      )
      return has
    },
    pushDownExternalHandlers() {
      return this.pushDownBtn?.guards || {}
    }
  },
  created() {},
  methods: {
    async pushDown({ config, evtName, methodName, button }) {
      // 加载下推类型选项
      if (this.hasPushDownBtn) {
        this.getPushDownOpt(this.pushDownBtn)
      } else console.info('无下推按钮')

      let selections
      if (this.currentPageType === 'list') {
        selections = this.getSelections()
        if (button.skipValidate !== true) {
          if (selections.length < 1) {
            return void this.$message.error('请先选择要下推的项')
          }
        }
      }

      let beforePushDownError = null
      let showPushDownDrawer = false
      if (typeof this.pushDownExternalHandlers?.beforePushDown === 'function') {
        const [err, res] = await getSyncAsyncReturns(
          this.pushDownExternalHandlers?.beforePushDown,
          null,
          selections
        )
        console.log('beforePushDown:', err, res)
        beforePushDownError = err
        showPushDownDrawer = res
      } else if (
        isPlainObject(this.pushDownExternalHandlers?.beforePushDown) &&
        typeof this.pushDownExternalHandlers.beforePushDown?.handler ===
          'function'
      ) {
        const [err, res] = await getSyncAsyncReturns(
          this.pushDownExternalHandlers?.beforePushDown.handler,
          this.pushDownExternalHandlers?.beforePushDown?.context || null,
          selections
        )
        beforePushDownError = err
        showPushDownDrawer = res
      } else {
        showPushDownDrawer = true
      }

      console.log('beforePushDown:', beforePushDownError)

      if (beforePushDownError) {
        return void console.error(
          'Error in "beforePushDown"',
          beforePushDownError
        )
      }

      if (typeof showPushDownDrawer !== 'boolean') {
        return void console.error(
          'Expected boolean from "beforePushDown", but accepted:',
          showPushDownDrawer
        )
      }
      this.pushDownVisible = showPushDownDrawer
    },
    /**
     * 获取下拉抽屉的选项
     * @description
     * - set this.pushDownOptions
     * - set this.documentTypeOpts
     */
    async getPushDownOpt(pushDownBtn) {
      const [getPushDownOptionsError, pushDownOptions] =
        await getSyncAsyncResult(pushDownBtn?.options?.pushDownTypeOpts)
      this.pushDownOptions = pushDownOptions || []

      const [getDocumentTypeOptsError, documentTypeOpts] =
        await getSyncAsyncResult(pushDownBtn?.options?.documentTypeOpts)
      this.documentTypeOpts = documentTypeOpts || []

      const [getReturnTypeOptsError, returnTypeOpts] = await getSyncAsyncResult(
        pushDownBtn?.options?.returnTypeOpts
      )
      this.returnTypeOpts = returnTypeOpts || []

      const [getLogisticsCompanyOptsError, logisticsCompanyOpts] =
        await getSyncAsyncResult(pushDownBtn?.options?.logisticsCompanyOpts)
      this.logisticsCompanyOpts = logisticsCompanyOpts || []

      if (getPushDownOptionsError) {
        console.error(
          '获取下推选项失败:',
          getPushDownOptionsError,
          pushDownBtn?.options?.pushDownTypeOpts
        )
      } else console.log('下推选项:', pushDownOptions)

      if (getDocumentTypeOptsError) {
        console.error(
          '获取下推类型失败:',
          getDocumentTypeOptsError,
          pushDownBtn?.options?.documentTypeOpts
        )
      } else console.log('下推类型:', documentTypeOpts)

      if (getReturnTypeOptsError) {
        console.error(
          '获取退货类型失败:',
          getReturnTypeOptsError,
          pushDownBtn?.options?.returnTypeOpts
        )
      } else console.log('下推类型:', returnTypeOpts)

      if (getLogisticsCompanyOptsError) {
        console.error(
          '获取物流公司类型失败:',
          getLogisticsCompanyOptsError,
          pushDownBtn?.options?.logisticsCompanyOpts
        )
      } else console.log('下推类型:', logisticsCompanyOpts)
    }
  }
}
